import Vue from 'vue'
import App from './App.vue'
import router from './router';

import Notifications from 'vue-notification'
import { ValidationProvider } from 'vee-validate';
import ToggleButton from 'vue-js-toggle-button'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Menubar from 'primevue/menubar'
import Dialog from 'primevue/dialog';

import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons
import 'vue-js-modal/dist/styles.css'                        //Modal

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('DataTable',DataTable);
Vue.component('Column',Column);
Vue.component('ColumnGroup',ColumnGroup);
Vue.component('InputText',InputText);
Vue.component('Button',Button);
Vue.component('Menubar',Menubar);
Vue.component('Dialog',Dialog);

Vue.use(Notifications);
Vue.use(ToggleButton);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
