<template>
  <div id='add_user'>
      <div class="volver">
          <img id='volver_img' src="../../assets/volver.png" alt="volver" @click="volver">
          <p class="volver_text">volver</p>
      </div>
      <div class="formulario">
        <p class="subtitulo">Foto de Avatar</p>
        <img v-show="avatar_exist" id="avatar_vista" class="avatar_vista_container" alt="avatar"/>
        <div v-show="!avatar_exist" class="avatar_vista_div"></div>        
        <input  type="file" id="image" accept="image/*" @change="uploadImageAvatar">
        <button @click="subirAvatar" class="añadir">Actualizar foto principal</button>

        <p class="subtitulo">Fotos Galería</p>
        <p class="texto"> Puedes agregar las fotos que desees </p>
        <p class="subtitulo"> Fotos Actuales </p>
        <div class="fotos_container">
          <img v-show="fotos_exist" v-for="(imagen, i) in dir_imagenes" :key="i"
                class="foto_perfil"
                v-bind:src="dir_imagenes[i]"
                alt="foto_perfil"
              />
        </div>
        <br>
        <input  type="file" id="image" accept="image/*" @change="uploadImage">
        <p class="subtitulo"> Fotos subidas </p>
        <div class="listaimg_container">
          <ul
              class="item_lista"
              v-for="item in lista"
              :key="item"
            >
              {{ item }}
            </ul>
        </div>
        <button @click="subirFoto" class="añadir">Subir foto de perfil</button>
      </div>
  </div>
</template>

<script>

import {Global} from "../../Global.js";
import axios from "axios";
import '../../axiosInterceptor.js';


export default {
  name: 'App',
  components: {
  

  },
  data(){
    return{
        fotos_exist:false,
        avatar_exist:false,
        foto_avatar:null,
        avatar_vista:null,
        foto_perfil:null,
        responseid:null,
        lista:[],
        name:null,
        ruta_generica: "http://escuadronmarketing.com/api/",
        imagenes:null,
        dir_imagenes:null
    }
  },
  methods:{
   volver(){
     this.$router.push({ name: 'menu_admin'}).catch(()=>{});
   },
   uploadImageAvatar:function(e){
     this.foto_avatar = e.target.files[0]
   },
   uploadImage:function(e){
     this.foto_perfil = e.target.files[0]
   },
   subirAvatar(){
     if(this.foto_avatar===null){
       this.$notify({
            type:'warn',
            group: 'foo',
            title: 'Sube una foto primero',
            text: '',        
          });
          return
     }
     let fd= new FormData()
    fd.append('avatar', this.foto_avatar);
    axios
    .post(Global.url+'users/avatar/'+String(this.responseid),fd)
    .then( (response=>{
      console.log(response);
      this.$notify({
            type:'success',
            group: 'foo',
            title: 'Avatar actualizado',
            text: '',        
          });
      this.traerUser();
    }))
    .catch( (error)=>{
      console.log(error)
      this.$notify({
            type:'error',
            group: 'foo',
            title: 'Error al subir la foto',
            text: '',
                        
          });
    })
   },
   subirFoto(){
     if(this.foto_perfil===null){
       this.$notify({
            type:'warn',
            group: 'foo',
            title: 'Sube una foto primero',
            text: '',        
          });
          return
     }
     let fd= new FormData()
    fd.append('file', this.foto_perfil);
    axios
    .post(Global.url+'images/file/'+String(this.responseid),fd)
    .then( (response)=>{
      console.log(response);
      this.$notify({
            type:'success',
            group: 'foo',
            title: 'Foto subida',
            text: '',        
          });
      let file=this.foto_perfil.name
      this.lista.push(file)
      this.getImagenes();
    })
    .catch( (error)=>{
      console.log(error);
      this.$notify({
            type:'error',
            group: 'foo',
            title: 'Error al subir la foto',
            text: '',
                        
          });
    })

   },
   render_avatar(){
      let ruta = this.avatar_vista.substr(7);
      document.getElementById("avatar_vista").src =
      this.ruta_generica + ruta;
   },
   traerUser(){
     axios
        .get(Global.url+'users/get-all')
        .then( (response)=>{
          let personas=response.data.data;
          for(let i in personas){
            if(personas[i].id==this.responseid){
                this.avatar_vista=personas[i].avatar
            }
        }   
        console.log(this.avatar_vista)
        if(this.avatar_vista!=null){
          this.avatar_exist=true;
          this.render_avatar();
        }else{
          this.avatar_exist=false;
        }
        this.render_avatar();
        })
        .catch( (error)=>{
          console.log(error)
        })
   },
   direcionImagenes(){
     let url = [];
      for (let i in this.imagenes) {
        url.push(this.ruta_generica + this.imagenes[i].image_url.substr(7));
      }
      this.dir_imagenes = url;
      this.fotos_exist=true;
   },
   getImagenes(){
     axios
        .get(Global.url + "images/" + this.responseid)
        .then((response) => {
          this.imagenes=response.data.data;
          this.direcionImagenes();
        })
        .catch((error) => {
          console.log(error);
        });
   }
  },
  mounted(){
    this.responseid=this.$route.params.id
    this.traerUser();
    this.getImagenes();
  }
}
</script>

<style lang="scss" scoped>

#add_user{
  background-color: #C7B9A9;
  min-height: 100vh;
  height: 100%;
}
 .volver{
    float: left;
  width: 10%;
  height: 100%;
  display: block;
  //background-color: sandybrown;
 }

 .formulario{
   padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    height: 100%;
    display: block;
    //background-color: gray;
 }

#volver_img{
    width: 50px;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.volver_text{
  margin-left: 30px;
  font-weight: bold;  
}

.titulo_seccion{
  margin-top: 20px;
  font-size: 22px;
  color: #302C28;
}
  
.subtitulo{
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.texto{
  margin-top: 20px;
  font-size: 15px;
}


.añadir{
  margin-top: 30px;
  background: #302C28;
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color:white;
  font-size: 15px;
  outline: none;
  border: 0;
  margin-bottom: 50px;
}

.listaimg_container{
  margin-top: 20px;
  overflow-y: scroll;
  width: 370px;
  height: 100px;
  background-color: aliceblue;
  border-radius: 10px;
}

::-webkit-scrollbar {
  display: none;
}

.item_lista{
  margin-top: 5px;
  margin-left: 10px;
  font-size: 15px;
  color: #302C28;
}

.avatar_vista_container{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100px;
  border-radius: 10px;
}

.avatar_vista_div{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  background-color: aliceblue;
  border-radius: 10px;
}

.fotos_container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow-y: scroll;
  width: 370px;
  height: 300px;
  background-color: aliceblue;
  border-radius: 10px;
}

.foto_perfil {
  margin-top: 20px;
  margin-left: 20px;
  width: 150px;
}
</style>