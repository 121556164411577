<template>
  <div class="mapaPrincipal">
    <div class="izq_principal">
      <img
        class="logo_menu"
        src="../assets/Escuadron.png"
        alt="Logo de Escuadrón Marketing"
      />
      <img
        class="imagen1_menu"
        src="../assets/Binoculares.png"
        alt="Binoculares"
      />
    </div>
    <div class="centro_principal">
      <div v-if="loaded == false">
        <scaling-squares-spinner
          class="spinner"
          :animation-duration="1250"
          :size="65"
          color="#da8f0f"
        />
      </div>
      <div v-if="loaded == true" class="mapa_principal">
        <modal
          v-show="isModalVisible"
          @close="closeModal"
        />
        <div class="lista_equipos">
          <p class="titulo_lista text text--extra-large">Elige en el mapa el</p>
          <p class="titulo_lista text text--extra-large">escuadrón que quieres conocer</p>
          <ol class="lista">
            <li
              class="text text--medium item_lista"
              v-for="dept in departamentos"
              :key="dept"
            >
              {{ dept }}
            </li>
          </ol>
        </div>
        <div class="mapa">
          <img class="imagen_isla" src="../assets/Isla.png" alt="imagen_isla" />
          <button class="boton boton1" type="button" @click="goDirectorio1">
            1
          </button>
          <button class="boton boton2" type="button" @click="goDirectorio2">
            2
          </button>
          <button class="boton boton3" type="button" @click="goDirectorio3">
            3
          </button>
          <button class="boton boton4" type="button" @click="goDirectorio4">
            4
          </button>
          <button class="boton boton5" type="button" @click="goDirectorio5">
            5
          </button>
          <button class="boton boton6" type="button" @click="goDirectorio6">
            6
          </button>
          <button class="boton boton7" type="button" @click="goDirectorio7">
            7
          </button>
          <button class="boton boton8" type="button" @click="goDirectorio8">
            8
          </button>
          <button class="boton boton9" type="button" @click="goDirectorio9">
            9
          </button>
          <button class="boton boton10" type="button" @click="goDirectorio10">
            10
          </button>
          <button class="boton boton11" type="button" @click="goDirectorio11">
            11
          </button>
          <img
            class="imagen imagen1"
            src="../assets/Algodon.png"
            alt="imagen_1"
          />
          <img class="imagen imagen2" src="../assets/Aji.png" alt="imagen_2" />
          <img
            class="imagen imagen3"
            src="../assets/Arroz.png"
            alt="imagen_3"
          />
          <img
            class="imagen imagen4"
            src="../assets/Banano.png"
            alt="imagen_4"
          />
          <img class="imagen imagen5" src="../assets/Cafe.png" alt="imagen_5" />
          <img
            class="imagen imagen6"
            src="../assets/Mazorca.png"
            alt="imagen_6"
          />
          <img
            class="imagen imagen7"
            src="../assets/Tomate.png"
            alt="imagen_7"
          />
          <img
            class="imagen imagen8"
            src="../assets/Algodon.png"
            alt="imagen_8"
          />
          <img 
            class="imagen imagen9"
            src="../assets/Aji.png"
            alt="imagen_9" 
          />
          <img
            class="imagen imagen10"
            src="../assets/Aji.png"
            alt="imagen_10"
          />
          <img
            class="imagen imagen11"
            src="../assets/Arroz.png"
            alt="imagen_11"
          />
          <img
            class="logo_bayer"
            src="../assets/logo_bayer.svg"
            alt="logo_bayer"
          />
        </div>
      </div>
      <div class="boton_cerrar">
        <button class="cerrar_sesion" type="button" @click="openModal">
          Cerrar sesión
        </button>
      </div>
      
    </div>
    <div class="der_principal">
      <img class="imagen2_menu" src="../assets/Lupa.png" alt="imagen2_menu" />
      <img class="imagen3_menu" src="../assets/Soga.png" alt="imagen3_menu" />
    </div>
  </div>
</template>

<script>
import { Global } from "../Global.js";
import _find from 'lodash/find';
import axios from "axios";
import "../axiosInterceptor.js";
import { ScalingSquaresSpinner } from "epic-spinners";
import modal from './aux_components/modal.vue';

export default {
  name: "App",
  components: {
    ScalingSquaresSpinner,
    modal
  },
  data() {
    return {
      departamentos: [],
      id_deptos: [],
      desc_dptos: [],
      loaded: false,
      isModalVisible: false,  
    };
  },
  methods: {
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    goDirectorio1() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[0]);
      localStorage.setItem("name_dpto", this.departamentos[0]);
      localStorage.setItem("des_dpto", this.desc_dptos[0]);
    },
    goDirectorio2() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[1]);
      localStorage.setItem("name_dpto", this.departamentos[1]);
      localStorage.setItem("des_dpto", this.desc_dptos[1]);
    },
    goDirectorio3() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[2]);
      localStorage.setItem("name_dpto", this.departamentos[2]);
      localStorage.setItem("des_dpto", this.desc_dptos[2]);
    },
    goDirectorio4() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[3]);
      localStorage.setItem("name_dpto", this.departamentos[3]);
      localStorage.setItem("des_dpto", this.desc_dptos[3]);
    },
    goDirectorio5() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[4]);
      localStorage.setItem("name_dpto", this.departamentos[4]);
      localStorage.setItem("des_dpto", this.desc_dptos[4]);
    },
    goDirectorio6() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[5]);
      localStorage.setItem("name_dpto", this.departamentos[5]);
      localStorage.setItem("des_dpto", this.desc_dptos[5]);
    },
    goDirectorio7() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[6]);
      localStorage.setItem("name_dpto", this.departamentos[6]);
      localStorage.setItem("des_dpto", this.desc_dptos[6]);
    },
    goDirectorio8() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[7]);
      localStorage.setItem("name_dpto", this.departamentos[7]);
      localStorage.setItem("des_dpto", this.desc_dptos[7]);
    },
    goDirectorio9() {
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[8]);
      localStorage.setItem("name_dpto", this.departamentos[8]);
      localStorage.setItem("des_dpto", this.desc_dptos[8]);
    },
    goDirectorio10(){
      this.$router.push({ name: 'directorio'});
      localStorage.setItem("id_dpto", this.id_deptos[9]);
      localStorage.setItem("name_dpto", this.departamentos[9]);
      localStorage.setItem("des_dpto", this.desc_dptos[9]);
    },
    goDirectorio11() {
      this.$router.push({ name: 'estadisticas'});
    },
    habilitarBotones() {
      this.loaded = true;
      for (let i = this.departamentos.length + 1; i < 11; i++) {
        let nombre_b = "boton_" + i;
        let nombre_img = "imagen" + i;
        let boton = document.getElementById(nombre_b);
        let img = document.getElementById(nombre_img);
        boton.style.display = "none";
        img.style.display = "none";
      }
    },
    getDepartments() {
      axios
        .get(Global.url + "departments")
        .then((response) => {
          console.log(response)
          let dep = [];
          let id = [];
          let des = [];
          let orderedDepartments = [1, 5, 2, 3, 4, 6, 7, 8, 9, 10];
          orderedDepartments.forEach((d) => {
            let depto = _find(response.data.data, { id: d });
            des.push(depto.description);
            dep.push(depto.name);
            id.push(depto.id);
          });

          dep.push("Estadísticas");
          this.desc_dptos = des;
          this.departamentos = dep;
          this.id_deptos = id;
          this.loaded = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDepartments();
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.spinner {
  margin: auto;
}

.mapaPrincipal {
  display: flex;
  background: url("../assets/wallpaper_main.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}

.izq_principal {
  padding-top: 60px;
  width: 20%;
  height: 100%;
}

.logo_menu {
  display: block;
  margin: auto;
  width: 203px;
}

.logo_modal {
  display: block;
  margin: 0px auto;
  width: 303px;
}

.texto_modal {
  text-align: center;
  margin: 10px 10px;
  color: #5e3c00;
}

.botones_modal_container {
  text-align: center;
  margin: 30px auto;
  width: 90%;
  height: 10px;
}

#boton_modal {
  width: 240px;
  height: 70px;
  background-image: url("../assets/boton.svg");
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  font-size: 18px;
  color: white;
  cursor: pointer;
  outline: none;
}

.imagen1_menu {
  padding-top: 60px;
  display: block;
  margin: auto;
  width: 241px;
}


.centro_principal {
  display: block;
  margin: auto;
  justify-content: center;
  float: left;
  margin: auto;
  justify-content: center;
  //width: 60%;
  position: relative;
  z-index: 10;
}

.der_principal {
  display: block;
  height: 100%;
  width: 20%;
}

.mapa_principal {
  display: flex;
  align-items: center;
  background: url("../assets/MapaPapel.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  max-width: 890px;
  height: 610px;
}

.imagen2_menu {
  margin: auto;
  padding-top: 200px;
  width: 279px;
}

.imagen3_menu {
  padding-top: 70px;
  margin: auto;
  width: 356px;
}

.boton_cerrar {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.cerrar_sesion {
  width: 240px;
  height: 70px;
  background-image: url("../assets/boton.svg");
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  font-size: map-get($font-sizes, "extra-large");
  color: white;
  cursor: pointer;
  outline: none;
  font-weight: bold;
}

.lista_equipos {
  display: block;
  margin-top: -25px;
  width: 320px;
}

.mapa {
  width: 650px;
  display: block;
}

.titulo_lista {
  margin-left: 60px;
  color: #5e3c00;
  font-weight: bold;
}

.lista {
  margin-left: 80px;
  padding-top: 30px;
  color: #5e3c00;
  font-size: 15px;
}

.item_lista {
  color: #5e3c00;
  padding-top: 10px;
}

.imagen_isla {
  width: 550px;
}

.boton {
  position: absolute;
  width: 43px;
  height: 42px;
  background-image: url("../assets/Grupo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  font-size: 19px;
  color: white;
  cursor: pointer;
  outline: none;
  z-index: 1;
}

.imagen {
  position: absolute;
  width: 38px;
  z-index: 0;
  transition: all 0.3s ease-in-out;
}

.boton1 {
  margin-top: 250px;
  margin-left: -500px;
}

.imagen1 {
  margin-top: 220px;
  margin-left: -500px;
  transition: all 0.3s ease-in-out;
}

.boton2 {
  margin-left: -475px;
  margin-top: 100px;
}

.imagen2 {
  margin-top: 65px;
  margin-left: -472px;
}

.boton3 {
  margin-top: 320px;
  margin-left: -440px;
}

.imagen3 {
  margin-top: 273px;
  margin-left: -432px;
}

.boton4 {
  margin-top: 230px;
  margin-left: -390px;
}

.imagen4 {
  margin-top: 185px;
  margin-left: -390px;
}

.boton5 {
  margin-top: 110px;
  margin-left: -325px;
}

.imagen5 {
  margin-top: 72px;
  margin-left: -325px;
}

.boton6 {
  margin-top: 50px;
  margin-left: -180px;
}

.imagen6 {
  margin-top: 8px;
  margin-left: -179px;
}

.boton7 {
  margin-top: 185px;
  margin-left: -100px;
}

.imagen7 {
  margin-top: 155px;
  margin-left: -100px;
}

.boton8 {
  margin-top: 280px;
  margin-left: -240px;
}

.imagen8 {
  margin-top: 250px;
  margin-left: -240px;
}

.boton9 {
  margin-top: 350px;
  margin-left: -300px;
}

.imagen9 {
  margin-top: 315px;
  margin-left: -300px;
}

.boton10 {
  margin-top: 150px;
  margin-left: -210px;
}

.imagen10 {
  margin-top: 110px;
  margin-left: -210px;
}

.boton11 {
  margin-top: 310px;
  margin-left: -140px;
}

.imagen11 {
  margin-top: 263px;
  margin-left: -132px;
}



.imagen:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.logo_bayer {
  position: absolute;
  width: 120px;
  z-index: 0;
  margin-top: -75px;
  margin-left: -130px;
  opacity: 70%;
}

@media screen and (max-width: 1280px) {
  .mapa_principal {
    height: 545px;
  }

  .mapa {
    margin-top: 40px;
  }
}
</style>