<template>
  <div class="statistics">
    <div class="statistics__list">
      <div class="list-container">
        <img
          class="tiki-image"
          src="../assets/TikiZanahoria.svg"
          alt="Tiki Zanahoria"
        />
        <div class="list">
          <div class="list__title text text--big">Estadísticas</div>
          <ul class="list__items">
            <li
              class="item"
              v-for="(member, index) in graphs"
              :key="index"
              :class="{ 'selected': index === graphNumber }"
              @click="selectGraph(index)"
            >
              {{ member.name }}
            </li>
          </ul>
        </div>
        <img class="scroll-image" src="../assets/scroll.svg" alt="Scroll image" />
      </div>
      <button class="back-button" @click="volverMapa">VOLVER</button>
    </div>
    <div class="statistics__images">
      <div class="graph-container">
        <img
          class="graph"
          :src="getImgUrl">
      </div>
    </div>
  </div>
</template>

<script>
import "../axiosInterceptor.js";

export default {
  name: "App",
  data() {
    return {
      graphs: [
        { name: 'Personas por área', file: 'PersonasPorArea.png' },
        { name: 'Género', file: 'Genero.png' },
        { name: 'Nacionalidad', file: 'Nacionalidad.png' },
        { name: 'Hijos', file: 'Hijos.png' },
        { name: 'Profesiones', file: 'Profesiones.png' },
        { name: 'Postgrados', file: 'Postgrados.png' },
        { name: 'Deportes', file: 'Deportes.png' },
        { name: 'Celebridades', file: 'Celebridades.png' },
        { name: 'Top 5 personajes', file: 'Personajes.png' },
      ],
      graphNumber: 0,
      selectedGraph: {},
    };
  },
  computed: {
    getImgUrl() {
      var images = require.context('../assets/statistics/', false, /\.png$/)
      return images('./' + this.selectedGraph.file)
    },
  },
  methods: {
    selectGraph(index) {
      this.graphNumber = index;
      this.selectedGraph = this.graphs[index];
    },
    volverMapa() {
      this.$router.push({ name: 'mapa'}).catch(()=>{});
    },
  },
  beforeMount() {
    this.selectedGraph = this.graphs[0];
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.statistics {
  display: flex;
  align-items: center;
  background: url("../assets/wallpaper_main.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.statistics__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 34%;
  height: 100%;
}

.list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: url("../assets/papel_lista.png");
  width: 400px;
  height: 370px;
}

.tiki-image {
  position: absolute;
  margin-top: -50px;
  margin-left: -200px;
  width: 80px;
  transform: rotate(-15deg);
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.list__title {
  margin-top: 50px;
  color: #5e3c00;
  font-weight: bold;
}

.scroll-image {
  position: absolute;
  margin-top: 130px;
  margin-left: 155px;
}

.list__items {
  margin-top: 20px;
  margin-left: 50px;
  list-style: none;
  overflow: auto;
  width: 72%;
  height: 70%;
  align-self: flex-start;
}

.item {
  margin: 10px;
  padding: 5px 15px;
  color: #5e3c00;
  font-size: map-get($font-sizes, "enormous");
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
}

.item:hover {
  background: #5e3c00;
  color: white;
}

.statistics__images {
  display: flex;
  align-items: center;
  width: 66%;
  height: 100%;
}

.graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 790px;
  height: 569px;
  background: url("../assets/MapaPapel.png");
}

.graph {
  width: 100%;
  max-width: 700px;
  margin-top: -20px;
}

.selected {
  background: #5e3c00;
  color: white;
}

.back-button {
  background-image: url("../assets/boton.svg");
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
  border: 0;
  margin-top: 10px;
  margin-right: 65px;
}
</style>