<template>
  <div class="resetpassword">
    <div class="text_rec text text--gigantic">Cambia tu contraseña</div>
    <div class="subtext_rec text text--big">
      Ingresa una nueva contraseña
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider name="password" rules="required|min|number|char">
          <div class="textbox1" slot-scope="{ errors }">
            <input
              class="input"
              v-model="password_1"
              type="password"
              placeholder="Nueva contraseña"/>
            <p class="errors_reset">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <ValidationProvider name="password" rules="required|min|number|char">
          <div class="textbox2" slot-scope="{ errors }">
            <input
              class="input"
              v-model="password_2"
              type="password"
              placeholder="Confirma la contraseña"/>
            <p class="errors_reset">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <div class="boton_reset">
          <button class="confirm_reset" type="submit">CREAR</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import axios from "axios";
import { Global } from "../Global";
import "../axiosInterceptor.js";

extend("required", {
  ...required,
  message: "Este campo es obligatorio",
});

extend("min", {
  message: "La contraseña debe tener minimo 8 caracteres",
  validate: (value) => {
    return value.length >= 8;
  },
});

extend("number", {
  message: "La contraseña debe tener por lo menos un número",
  validate: (value) => {
    let cont = 0;
    let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    for (let i in value) {
      for (let j in numbers) {
        if (value[i] == numbers[j]) {
          cont = cont + 1;
        }
      }
    }
    if (cont >= 1) {
      return true;
    } else {
      return false;
    }
  },
});

extend("char", {
  message: "La contraseña debe tener por lo menos una letra",
  validate: (value) => {
    let cont = 0;
    let letras = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "ñ",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "Ñ",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    for (let i in value) {
      for (let j in letras) {
        if (value[i] == letras[j]) {
          cont = cont + 1;
        }
      }
    }
    if (cont >= 1) {
      return true;
    } else {
      return false;
    }
  },
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      password_1: "",
      password_2: "",
      password: "",
      correo: "",
    };
  },
  methods: {
    onSubmit() {
      if (this.password_1 == this.password_2) {
        this.password = this.password_1;
        this.redirigir();
      } else {
        this.$notify({
          type: "error",
          group: "foo",
          title: "Error",
          text: "Las contraseñas no son iguales",
        });
      }
    },
    confirmChange() {
      this.$notify({
        type: "success",
        group: "foo",
        title: "Contraseña Actualizada",
        text: "La contraseña se ha cambiado",
      });
    },
    redirigir() {
      let datos = JSON.stringify({
        user: {
          email: this.correo,
          password: this.password,
        },
      });
      axios
        .post(Global.url + "users/change-password", datos, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            localStorage.clear();
            this.confirmChange();
            this.$router.push({ name: 'inicio_sesion'}).catch(()=>{});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.correo = localStorage.getItem("correo");
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.resetpassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/wallpaper.svg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.text_rec,
.subtext_rec {
  display: block;
  text-align: center;
}

.text_rec {
  font-weight: bold;
}

.subtext_rec {
  margin-top: 15px;
  margin-bottom: 50px;
}

.reset_text {
  display: block;
  text-align: center;
}

.textbox1 {
  display: block;
  text-align: center;
  height: 80px;
}

.textbox2 {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  height: 80px;
}

.input {
  background: #05352d;
  border: 4px solid #0c5045;
  width: 330px;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  color: white;
  font-size: map-get($font-sizes, 'medium');
  box-shadow: 5px 5px 5px black;
  outline: none;
}

@media (max-width: 389px) {
  .reset_input1,
  .reset_input2 {
    background: .05352d;
    border: 4px solid .0c5045;
    width: 160px;
    height: 10px;
    padding: 12px 20px;
    border-radius: 4px;
    color: white;
    font-size: 15px;
    box-shadow: 5px 5px 5px black;
    outline: none;
  }
}

.input:focus {
  border: 3px solid rgb(10, 10, 10);
}

.boton_reset {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.confirm_reset {
  background-image: url("../assets/boton.svg");
  background-color: transparent;
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 240px;
  height: 70px;
  font-size: 22px;
  color: white;
  cursor: pointer;
  outline: none;
  border: 0;
}

.errors_reset {
  color: rgb(211, 31, 31);
  padding-top: 10px;
}

@media (max-width: 389px) {
  .confirm_reset {
    background-image: url("../assets/boton.svg");
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 210px;
    height: 38px;
    font-size: 22px;
    color: white;
    cursor: pointer;
    outline: none;
    border: 0;
  }
}
</style>