import axios from 'axios';
import router from './router';

//Interceptor
axios.interceptors.request.use(function(config) {
  const auth_token = localStorage.getItem('user_token');
  if(auth_token) {
    config.headers.Authorization = `Bearer ${auth_token}`;
  }
  return config;
});

axios.interceptors.response.use(function(response) {
  if (response.headers['x-auth']) {
    localStorage.setItem('user_token', response.headers['x-auth']);
  }
  return response;
}, function(error) {
  console.log(error);
  if (error.response.status === 401) {
    localStorage.clear();
    router.push({ name: 'inicio_sesion'}).catch(()=>{});
  }
  return Promise.reject(error); 
});
