<template>
  <div class="recpassword">
    <div class="text_rec text text--gigantic">Recuperar Contraseña</div>
    <div class="subtext_rec text text--big">
      Ingresa el correo inscrito y te enviaremos un correo
    </div>
    <div class="rec_texbox">
      <input
        class="input"
        placeholder="Correo"
        type="text"
        v-model="correo"
      />
    </div>
    <div class="rec_boton">
      <button class="boton_enviar" type="button" @click="goLogin">
        ENVIAR
      </button>
    </div>
    <div class='rec_volver_is'>
      <div class="line"></div>
      <div
        class="text_volver text text--big"
        @click="returnLogin">
        Ingresa con tu cuenta
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { Global } from "../Global.js";
import axios from "axios";
import "../axiosInterceptor.js";

export default {
  name: "App",
  components: {
    //HelloWorld
  },
  data() {
    return {
      correo: "",
    };
  },
  methods: {
    goLogin() {
      let data = JSON.stringify({
        user: {
          email: this.correo,
        },
      });

      axios
        .post(Global.url + "auth/request-password", data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(response);
          this.$router.push({ name: 'inicio_sesion'});
          this.$notify({
            type: "success",
            group: "foo",
            title: "Correo Enviado",
            text: "¡Revisa tu bandeja de entrada!",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            group: "foo",
            title: "Correo no registrado",
            text: "¡intenta de nuevo!",
          });
        });
    },
    returnLogin() {
      this.$router.push({ name: 'inicio_sesion'}).catch(()=>{});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.recpassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url("../assets/wallpaper.svg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.text_rec,
.subtext_rec {
  display: block;
  text-align: center;
}

.text_rec {
  font-weight: bold;
}

.subtext_rec {
  margin-top: 15px;
  margin-bottom: 15px;
}

.rec_texbox {
  display: block;
  text-align: center;
  padding-top: 100px;
}

.input {
  background: #05352d;
  border: 4px solid #0c5045;
  width: 330px;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  color: white;
  font-size: map-get($font-sizes, 'medium');
  box-shadow: 5px 5px 5px black;
  outline: none;
}

.rec_boton {
  display: block;
  text-align: center;
  padding-top: 100px;
}

@media (min-width: 389px) and (max-width: 1700px) {
  .rec_texbox {
    display: block;
    text-align: center;
    padding-top: 30px;
  }
  .rec_boton {
    display: block;
    text-align: center;
    padding-top: 50px;
  }
}

@media (max-width: 389px) {
  .input_rec {
    background: 0.05352d;
    border: 4px solid 0c5045;
    width: 160px;
    height: 10px;
    padding: 12px 20px;
    border-radius: 4px;
    color: white;
    font-size: 15px;
    box-shadow: 5px 5px 5px black;
    outline: none;
  }
}

.input:focus {
  border: 3px solid rgb(10, 10, 10);
}

.boton_enviar {
  background-image: url("../assets/boton.svg");
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
  border: 0;
}

@media (max-width: 389px) {
  .boton_enviar {
    background-image: url("../assets/boton.svg");
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 210px;
    height: 40px;
    font-size: 22px;
    color: white;
    cursor: pointer;
    outline: none;
    border: 0;
  }
}

.rec_volver_is {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 80px;
}

.line {
  background-color: white;
  margin: 0 10px;
  width: 80px;
  height: 2px
}

.text_volver {
  display: block;
  text-align: center;
  cursor: pointer;
}
</style>