import Vue from 'vue';
import Router from 'vue-router';

// import Landing from '../components/Landing.vue'
import Inicio from '../components/Inicio.vue'
import InicioSesion from '../components/InicioSesion.vue'
import RecuperarContraseña from '../components/RecuperarContraseña.vue'
import Reset from '../components/Reset.vue'
import Test from '../components/Test.vue'
import Directorio from '../components/Directorio.vue'
import MapaPrincipal from '../components/MapaPrincipal.vue'
import Estadisticas from '../components/Estadisticas.vue'
import AdminLogin from '../components/admin_components/AdminLogin.vue'
import MenuAdmin from '../components/admin_components/MenuAdmin.vue'
import AñadirIntegrante from '../components/admin_components/AñadirIntegrante.vue'
import EditarIntegrante from '../components/admin_components/EditarIntegrante.vue'
import SubirImagenes from '../components/admin_components/SubirImagenes.vue'

Vue.use(Router);

const routes = [
  {
    path:'/',
    component: Inicio,
    name:'inicio'
  },
  {
    path:'/inicio-sesion',
    component: InicioSesion,
    name:'inicio_sesion'
  },
  {
    path:'/recuperar-clave',
    component: RecuperarContraseña,
    name:'recuperar'
  },
  {
    path:'/cambiar-clave',
    component: Reset,
    name:'reset',
    meta: {
      auth: true,
    },
  },
  {
    path:'/encuesta',
    component: Test,
    name:'encuesta',
    meta: {
      auth: true,
    },
  },
  {
    path:'/mapa',
    component: MapaPrincipal,
    name:'mapa',
    meta: {
      auth: true,
    },
  },
  {
    path:'/directorio',
    component: Directorio,
    name:'directorio',
    meta: {
      auth: true,
    },
  },
  {
    path:'/estadisticas',
    component: Estadisticas,
    name: 'estadisticas',
    meta: {
      auth: true,
    },
  },
  {
    path:'/admin_login',
    component: AdminLogin,
    name: 'admin_login',
  },
  {
    path:'/admin_main',
    component: MenuAdmin,
    name: 'menu_admin',
    meta: {
      adminAuth: true,
    },
  },
  {
    path:'/add_user',
    component: AñadirIntegrante,
    name: 'add_miembro',
    meta: {
      adminAuth: true,
    },
  },
  {
    path:'/edit_user',
    component: EditarIntegrante,
    name: 'edit_miembro',
    meta: {
      adminAuth: true,
    },
  },
  {
    path:'/add_img:id',
    component: SubirImagenes,
    name: 'add_image',
    meta: {
      adminAuth: true,
    },
  },
];

const router = new Router({
  routes,
  mode:'history',
  linkActiveClass: 'active',
  transactionOnLoad: true,
  base: '/',
});

router.beforeEach((to, from, next) => {
  if (to.meta.adminAuth && (!localStorage.getItem('admin') || !localStorage.getItem('user_token'))) {
    next({ name: 'admin_login' });
  } else if (to.meta.auth && !localStorage.getItem('user_token')) {
    next({ name: 'inicio_sesion' });
  } else if (localStorage.getItem('user_token')) {
    if (localStorage.getItem('cps') === 'true') {
      if (to.name !== 'reset') {
        next({ name: 'reset' });
      } else {
        next();
      }
    } else if (localStorage.getItem('cps') === 'false') {
      if (!localStorage.getItem('test_state')) {
        if (to.name !== 'encuesta') {
          next({ name: 'encuesta' });
        } else {
          next();
        }
      } else if (localStorage.getItem('test_state') === 'true') {
        if (to.name === 'encuesta' || to.name === 'inicio_sesion' || to.name === 'recuperar' || to.name === 'reset') {
          next({ name: 'mapa' });
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;