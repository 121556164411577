<template>
  <div id='inicioSesion_admin'>
      <div id='logo_iniciosesion_admin'>
      <img id='img_iniciosesion_admin' src="../../assets/Administrador.png" alt="logo_BayerManji">
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider name="email" rules="required|email">
      <div class="textbox1_a" slot-scope="{ errors }">
        <input id="input1_a" v-model="correo" placeholder="Correo">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <ValidationProvider name="password" rules="required|min">
      <div class="textbox2_a" slot-scope="{ errors }">
        <input id="input2_a" v-model="contraseña"  type="password" placeholder="Contraseña">
        <p  class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <div id="boton_is_a">
      <button id="submit_boton_is_a" type="submit">INICIA SESIÓN</button>
    </div>
    </form>
  </ValidationObserver>
   
  </div>
</template>
<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import {Global} from "../../Global.js";
import axios from "axios";
import '../../axiosInterceptor.js';


extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
 
// Add the email rule
extend('email', {
  ...email,
  message: 'Ingrese un correo valido'
});
//add min characters rule
extend('min', value => {
  return value.length >= 6;
});


export default {
  name: 'App',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data(){
    return{
      correo:"",
      contraseña:"",
      //admin:true,

    }
  },
  
  
  methods:{
    onSubmit(){
      this.adminExiste();
    },
    adminExiste(){
      let data=JSON.stringify({
        login:{
          email:this.correo,
          password:this.contraseña
        }
      });

      axios
      .post(Global.url+'auth',data,{headers:{"Content-Type" : "application/json"}})
      .then( (response)=>{
        if(response.status==200){
          console.log(response)
          if(response.data.data.is_admin==true){
            localStorage.setItem('user_token',response.headers['x-auth']);
            localStorage.setItem('admin',response.data.data.is_admin);
            this.$router.push({ name: 'menu_admin'}).catch(()=>{});
          }else{
            this.$notify({
            type:'error',
            group: 'foo',
            title: 'No eres administrador',
            text: '¡Intenta de nuevo!',         
            });
          }
        }
      })
      .catch( (error)=>{
        console.log(error)
        this.$notify({
            type:'error',
            group: 'foo',
            title: 'Credenciales incorrectas',
            text: '¡Intenta de nuevo!',         
            });
      })
    },
  },
}
</script>

<style lang="scss" scoped>

#inicioSesion_admin{
  background-color: #C7B9A9;
  min-height: 100vh;
}

#logo_iniciosesion_admin{
  padding-top: 100px;
}

#img_iniciosesion_admin{
  display:block;
  margin:auto;
  width:320px;
  height:282px
}

.textbox1_a{
  display: block;
  text-align: center;
  padding-top: 30px;
}

.textbox2_a{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-top: 30px;
}

#input1_a,#input2_a{
  background: white;
  width: 370px;
  height: 60px;
  padding: 12px 20px;
  border-radius: 4px;
  color:rgb(182, 159, 117);
  font-size: 15px;
  outline: none;
  border: 0;
}

#input1_a:focus,#input2_a:focus{
  border: 3px solid rgb(213, 216, 213);
}

#boton_is_a{
  display: block;
  text-align: center;
  margin-top: 40px;
}

#submit_boton_is_a{
  background-color:#302C28;
  width: 370px;
  height: 55px;
  font-size: 22px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  outline: none; 
  border: 0;
  text-align: center;
}

.errors {
  color: rgb(211, 31, 31);
  padding-top: 10px;
  font-weight: bold;
}

</style>
