<template>
  <div v-if="questions.length == 0">
    <scaling-squares-spinner
      class="spinner"
      :animation-duration="1250"
      :size="65"
      color="#da8f0f"/>
  </div>
  <div v-else class="testprogress">
    <img
      class="img_izq"
      src="../../assets/maiz-cafe.png"
      alt="maiz-cafe"/>
    <div class="centro">
      <div class="progressbar">
        <h3 class="bar"></h3>
      </div>
      <div class="logo_pregunta">
        <img
          class="img_pregunta"
          :src="getImgUrl">
      </div>
      <div class="pregunta">
        <div class="enunciado_p text text--big">
          {{ currentQuestion.text }}
        </div>
      </div>
      <div
        class="boton_test">
        <button
          v-for="(answer, index) in currentQuestion.answers"
          :key="index"
          class="estilo_boton"
          :class="{
            'correct': answer.is_correct && currentAnswer != -1,
            'wrong': !answer.is_correct && currentAnswer === index,
            'disabled': currentAnswer !== -1
          }"
          @click="chooseAnswer(index)">
          {{ answer.text }}
        </button>
      </div>
      <div class="boton_siguiente">
        <button
          class="estilo_boton_siguiente"
          @click="nextQuestion"
          id="opcion_siguiente">
          SIGUIENTE
        </button>
      </div>
    </div>
    <img
      class="img_der"
      src="../../assets/pim-maiz.png"
      alt="pim-maiz"/>
  </div>
</template>

<script>
import _findIndex from 'lodash/findIndex';
import _shuffle from 'lodash/shuffle';
import { Global } from "../../Global.js";
import axios from "axios";
import "../../axiosInterceptor.js";
import { ScalingSquaresSpinner } from "epic-spinners";

export default {
  components: {
    ScalingSquaresSpinner,
  },
  data() {
    return {
      questions: [],
      questionNumber: 0,
      currentQuestion: {},
      currentAnswer: -1,
      chosenAnswers: [],
      score: 0,
      images: [
        'Binoculares2.png',
        'Maleta.png',
        'Botas.png',
        'Brujula.png',
        'Carpa.png',
        'Fosforos.png',
        'Gorra.png',
        'Mapa_1.png',
        'Sleeping.png',
        'Linterna.png',
      ],
    };
  },
  computed: {
    getImgUrl() {
      var images = require.context('../../assets/', false, /\.png$/)
      return images('./' + this.images[this.questionNumber])
    },
  },
  methods: {
    chooseAnswer(index) {
      if (this.currentAnswer === -1) {
        this.currentAnswer = index;
      }
    },
    nextQuestion() {
      // Verify if an answer is selected
      if (this.currentAnswer === -1) {
        this.$notify({
          type: "warn",
          group: "foo",
          title: "¡Espera!",
          text: "Escoge una respuesta",
        });
        return;
      }

      // Save chosen answer
      this.chosenAnswers.push(this.currentQuestion.answers[this.currentAnswer].id);

      // Calculate score
      this.score += this.currentAnswer === _findIndex(this.currentQuestion.answers, {
        is_correct: true
      }) ? 10 : 0;

      // If last question was answered
      if (this.questionNumber === this.questions.length - 1) {
        // Finish test
        let data = {
          score: this.score,
          answers: this.chosenAnswers
        }
        this.$emit("testTerminado", data);
      } else {
        // Display next question
        this.questionNumber += 1;
        this.currentQuestion = this.questions[this.questionNumber];
        this.currentQuestion.answers = _shuffle(this.currentQuestion.answers);
        this.currentAnswer = -1;
      }
    },
    getEncuesta() {
      axios
        .get(Global.url + "survey")
        .then((response) => {
          this.questions = _shuffle(response.data.data);
          this.currentQuestion = this.questions[this.questionNumber];
          this.currentQuestion.answers = _shuffle(this.currentQuestion.answers);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.getEncuesta();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../scss/styles.scss";

.testprogress {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.spinner {
  margin: 200px auto;
}

.img_izq {
  position: absolute;
  margin-top: 230px;
  margin-left: -70px;
  width: 150px;
  z-index: 0;
}

.centro {
  padding: 30px 50px;
  float: left;
  background: url("../../assets/MapaPapelrotado.png");
  background-position: center;
  background-size: cover;
  width: 600px;
  height: 700px;
  z-index: 10;
}

.img_der {
  position: absolute;
  margin-top: 260px;
  margin-left: 520px;
  width: 160px;
}

.progressbar {
  padding-top: 25px;
  color: #5e3c00;
}

.bar {
  display: block;
  text-align: center;
}

.logo_pregunta {
  padding-top: 15px;
  height: 100px;
}

.img_pregunta {
  display: block;
  margin: auto;
  height: 100%;
}

.pregunta {
  padding: 30px 45px 0;
}

.enunciado_p {
  display: block;
  text-align: center;
  color: #5e3c00;
  line-height: 1.4em;
}

.boton_test {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.estilo_boton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  background: #05352d;
  border: 4px solid #0c5045;
  padding: 2px 20px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  box-shadow: 5px 5px 5px black;
  cursor: pointer;
  width: 330px;
  min-height: 50px;
}

.correct {
  background-color: #00FF77;
}

.wrong {
  background-color: #D3203A;
}

.disabled {
  cursor: not-allowed;
  outline: none;
}

.boton_siguiente {
  display: block;
  text-align: center;
  margin-top: 40px;
}

.estilo_boton_siguiente {
  background-image: url("../../assets/boton.svg");
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
  border: 0;
  text-align: center;
}
</style>