<template>
  <div class='inicioSesion'>  
    <img
      class='img_inicio'
      src="../assets/Escuadron.png"
      alt="Logo Escuadrón Marketing">
    <div class='text_iniciosesion text text--large'>
      La aventura está empezando
    </div>
    <div class='text_iniciosesion text text--large'>
      y el Escuadrón Marketing te está necesitando
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider name="email" rules="required|email">
          <div class="textbox1" slot-scope="{ errors }">
            <input class="input" v-model="correo" placeholder="Correo">
            <p class="errors">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <ValidationProvider name="password" rules="required|min">
          <div class="textbox2" slot-scope="{ errors }">
            <input class="input" v-model="contraseña"  type="password" placeholder="Contraseña">
            <p class="errors">{{ errors[0] }}</p>
          </div>
        </ValidationProvider>
        <div class="boton_is">
          <button class="submit_boton_is" type="submit">INICIAR SESIÓN</button>
        </div>
      </form>
    </ValidationObserver>
    <div class='forgot-password'>
      <div class="line"></div>
      <div
        class="text-olvidar text text--big"
        @click="goReset">
        Olvidé mi contraseña
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import {Global} from "../Global.js";
import axios from "axios";
import '../axiosInterceptor.js';

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
 
// Add the email rule
extend('email', {
  ...email,
  message: 'Ingrese un correo valido'
});
//add min characters rule
extend('min', value => {
  return value.length >= 6;
});

export default {
  name: 'App',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      correo:"",
      contraseña:"",
      submitted:false,
      test_realizado:true,
      token_momentaneo:12345,
    }
  },
  
  methods:{
    onSubmit() {
      this.usuarioExiste();
    },
    usuarioExiste() {
      let data=JSON.stringify({
        login:{
          email:this.correo,
          password:this.contraseña
        }
      });
    axios
    .post(Global.url+'auth',data,{headers:{"Content-Type" : "application/json"}})
    .then( (response)=>{
      if(response.status==200){
        let estado_change=response.data.data.change_password_state;
        localStorage.setItem('cps',response.data.data.change_password_state)
        localStorage.setItem('user_token',response.headers['x-auth']);
        localStorage.setItem('user_names',response.data.data.names);
        localStorage.setItem('user_id',response.data.data.id);
        
        if (estado_change==true) {
          localStorage.setItem('correo',this.correo)
          this.$router.push({ name: 'reset'}).catch(()=>{});
        } else {
          if (response.data.data.survey_done === true) {
            localStorage.setItem('test_state',response.data.data.survey_done)
            this.$router.push({ name: 'mapa'});
          } else {
            this.$router.push({ name: 'encuesta'}).catch(()=>{});
          }
        }
        return true; 
      }
    })
    .catch( (error)=>{
      console.log(error);
      this.$notify({
        type:'error',
        group: 'foo',
        title: 'Credenciales Incorrectas',
        text: '¡Intenta de nuevo!',
                    
      });
    }) 
     
    },
    goReset(){
      this.$router.push({ name: 'recuperar'}).catch(()=>{});
    },
  },
  mounted() {
    var audio=new Audio(require("../sounds/Audio02.mp3"))
    audio.volume=0.2;
    audio.play();
  }
}
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.inicioSesion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/wallpaper.svg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.img_inicio {
  display: block;
  cursor: pointer;
  width: 311px;
  height: 237px;
  margin-bottom: 10px;
}

form {
  margin-top: 30px;
}

.text_iniciosesion {
  text-align: center;
  font-weight: bold;
  line-height: 1.4em;
}

.textbox1 {
  display: block;
  text-align: center;
  height: 80px;
}

.textbox2 {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  height: 80px;
}

.input {
  background: #05352d;
  border: 4px solid #0c5045;
  width: 330px;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  color: white;
  font-size: map-get($font-sizes, 'medium');
  box-shadow: 5px 5px 5px black;
  outline: none;
}

.input {
  border: 3px solid rgb(10, 10, 10);
}

.boton_is {
  margin-top: 15px;
  text-align: center;
}

.submit_boton_is {
  background-image: url("../assets/boton.svg");
  background-color: transparent;
  background-position-y: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 250px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none; 
  border: 0;
  text-align: center;
}


.forgot-password {
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.line {
  background-color: white;
  margin: 0 10px;
  width: 80px;
  height: 2px
}

.text-olvidar {
  display: block;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.errors {
  color: rgb(211, 31, 31);
  padding-top: 10px;
  font-weight: bold;
}

@media (min-width: 389px) and (max-width: 1600px) {
  .img_iniciosesion {
    display:block;
    margin:auto;
    width:300px;
    height:192px
  }
}

@media (max-width: 389px) {
  .img_iniciosesion{
    display:block;
    margin:auto;
    width:180px;
    height:102px
  }
}

@media (max-width: 389px) {
  .input1, .input2 {
    background: #05352d;
    border: 4px solid #0c5045;
    width: 160px;
    height: 10px;
    padding: 12px 20px;
    border-radius: 4px;
    color: white;
    font-size: 15px;
    box-shadow: 5px 5px 5px black;
    outline: none;
  }
}
</style>
