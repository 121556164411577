<template>
  <div class="testfinish">
    <div class="izq">
      <img
        class="antorcha_i"
        src="../../assets/antorcha_i.svg"
        alt="antorcha_i"
      />
    </div>
    <div class="centro">
      <div class="logo_testr">
        <img
          v-if="puntaje_respuesta >= 60"
          class="mascara_aprob"
          src="../../assets/TikiTomate.svg"
          alt="antorcha_aprob"/>
        <img
          v-else
          class="mascara_rep"
          src="../../assets/TikiTriste.svg"
          alt="antorcha_rep"/>
      </div>
      <div class="puntaje_text text text--gigantic">
        Puntaje Final
      </div>
      <div class="puntaje_value text text--extra-gigantic">
        {{ puntaje_respuesta }}
      </div>
      <div v-if="puntaje_respuesta >= 60" class="subtext_resultado">
        <h3 class="contexto_resultado">
          Una prueba difícil acabas de pasar y para tu <br />
          equipo conocer debes continuar
        </h3>
      </div>
      <div v-else class="subtext_resultado">
        <div class="contexto_resultado text text--bold text--gigantic">
          ¡No te rindas!
        </div>
        <div class="contexto_resultado text text--big">
          Tu experiencia esta creciendo y las pruebas
        </div>
        <div class="contexto_resultado text text--big">
          se irán haciendo mucho mas fáciles
        </div>
      </div>
      <div v-if="puntaje_respuesta >= 60" class="boton_final_test">
        <button class="fin_test" type="button" @click="irMapa">
          IR AL MAPA
        </button>
      </div>
      <div v-else class="boton_final_test">
        <button
          class="fin_test"
          type="button"
          @click="resetTest">
          REINTENTAR
        </button>
      </div>
    </div>
    <div class="der">
      <img
        class="antorcha_d"
        src="../../assets/antorcha_d.svg"
        alt="antorcha_dr"
      />
    </div>
  </div>
</template>

<script>
import { Global } from "../../Global.js";
import axios from "axios";
import "../../axiosInterceptor.js";

export default {
  data() {
    return {};
  },
  props: {
    puntaje_respuesta: null,
    respuestas: null,
  },
  methods: {
    resetTest() {
      location.reload();
    },
    irMapa() {
      this.updateTestState();
      localStorage.setItem('test_state', true);
      this.$router.push({ name: 'mapa'}).catch(()=>{});
    },
    crearJSON() {
      let id = localStorage.getItem("user_id");
      let array = [];
      for (let i in this.respuestas) {
        array.push({
          user_id: Number(id),
          answer_id: this.respuestas[i],
        });
      }
      let datos = JSON.stringify({
        result: array,
      });
      console.log(datos);
      return datos;
    },
    updateTestState() {
      let datos = this.crearJSON();

      axios
        .post(Global.url + "results", datos, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var audio = new Audio(require("../../sounds/Audio01.mp3"));
    audio.volume = 0.2;
    audio.play();
  },
};
</script>

<style lang="scss" scoped>
@import "./../../scss/styles.scss";

.testfinish {
  display: flex;
  background: url("../../assets/MapaPapel.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.izq {
  width: 200px;
  height: 100%;
}

.antorcha_i {
  margin-left: -120px;
  width: 260px;
}

.centro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  width: 410px;
  height: 100%;
}

.der {
  width: 200px;
  height: 100%;
}

.antorcha_d {
  margin-left: 60px;
  width: 260px;
}

.mascara_aprob,
.mascara_rep {
  display: block;
  margin: auto;
  margin-top: -160px;
  width: 160px;
}

.puntaje_text,
.puntaje_value {
  padding-top: 0px;
  color: #5e3c00;
  font-weight: bold;
  text-align: center;
}

.puntaje_text {
  margin-top: 20px;
}

.score_value {
  display: block;
  text-align: center;
  font-size: 110px;
}

.subtext_resultado {
  margin-top: 20px;
  color: #5e3c00;
}

.contexto_resultado {
  display: block;
  text-align: center;
  color: #5e3c00;
  line-height: 1.4em;
}

.boton_final_test {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.fin_test {
  background-image: url("../../assets/boton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
}

@media (max-height: 720px) {
  .antorcha_i {
    margin-left: -90px;
  }

  .antorcha_dr {
    margin-left: 90px;
  }

  .logo_testr {
    display: block;
    margin: auto;
    margin-top: -40px;
    width: 100px;
  }

  .boton_final_test {
    display: block;
    text-align: center;
    padding-top: 0px;
  }

  .score_value {
    display: block;
    text-align: center;
    font-size: 90px;
  }
}
</style>