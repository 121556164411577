<template>
  <div id="container">
    <!--<Inicio></Inicio>-->
    <router-view/>
    <notifications group="foo" classes="vue-notification"/>
  </div>
</template>

<script>


//import Inicio from './components/Inicio.vue'
export default {
  name: 'App',
  components: {
    //Inicio,
    
  },
}
</script>

<style lang="scss">

@font-face {
  font-family: "Noto Sans";
  src: url('https://fonts.googleapis.com/css2?family=Noto+Sans');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@700');
  font-weight: bold;
  font-style: bold;
}

*{
  margin:0;
  padding:0;
  font-family: 'Noto Sans', sans-serif;
  font-weight: normal;
}

.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;
  width: 400px;
  height: 90px;
  font-size: 12px;
 
  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
  .notification-title {
    font-size: 23px;
  }
 
  .notification-content {
    font-size: 15px;
  }
 
  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }
 
  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
 
  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
}
</style>
