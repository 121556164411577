<template>
  <div class='inicio'>
    <img
      class='img_inicio'
      src="../assets/Escuadron.png"
      alt="Logo Escuadrón Marketing"
      @click="goToLogin">
    <div class='text_inicio'>
      <img
        class='img_click'
        src="../assets/ClickMano.svg"
        alt="Ícono de mano"
        @click="goToLogin">
      <div
        class='text text--big'
        @click="goToLogin">
        Click para iniciar
      </div>
    </div>
    <div class='subtext_inicio text'>Para tener una experiencia completa<br> abre este sitio web en tu computador.</div> 
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  methods:{
    goToLogin(){
      this.$router.push({ name: 'inicio_sesion'}).catch(()=>{});
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.inicio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets/wallpaper.svg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.img_inicio {
  display: block;
  cursor: pointer;
  margin: auto 0 0;
  width: 376px;
  height: 290px;
}

.text_inicio {
  margin-top: 20px;
  .text {
    cursor: pointer;
    margin-top: 7px;
    font-weight: bold;
  }
}

.img_click {
  display: block;
  cursor: pointer;
  margin: auto;
  width: 21px;
}

.subtext_inicio {
  margin-top: auto;
  margin-bottom: 70px;
  line-height: 1.4em;
}

@media (max-width: 380px) {
  .img_inicio {
    width: 90%;
    height: auto;
  }
}
</style>