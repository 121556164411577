<template>
  <div class="testInicio">
    <div class="izq">
      <img class="antorcha_i" src="../../assets/antorcha_i.svg" alt="antorcha_i" />
    </div>
    <div class="centro">
      <div class="mascara_i">
        <img class="logo_i" src="../../assets/mascara_i.svg" alt="Mascara" />
      </div>
      <div class="text_i">
        <div class="salute_text text text--gigantic">Hola</div>
        <div class="text_username text text--gigantic">{{ user_name }}</div>
      </div>
      <div class="subtext_i">
        <div class="subtext_username text text--big">
          Nuestros más profundos tesoros podemos perder
        </div>
        <div class="subtext_username text text--big">
          si los acertijos no sabemos responder.
        </div>
        <div class="subtext_username text text--big">
          Para continuar al menos 60 puntos debes obtener.
        </div>
      </div>
      <div class="boton_i">
        <button
          class="iniciar_test"
          type="button"
          @click="cambiar_vista_test">
          EMPEZAR
        </button>
      </div>
    </div>
    <div class="der">
      <img class="antorcha_d" src="../../assets/antorcha_d.svg" alt="antorcha_d" />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  props: ["user_name"],
  data() {
    return {
      iniciar: false,
    };
  },
  methods: {
    cambiar_vista_test() {
      this.iniciar = true;
      this.$emit("iniciarTest", this.iniciar);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "./../../scss/styles.scss";

.testInicio {
  display: flex;
  background: url("../../assets/MapaPapel.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.izq {
  width: 200px;
  height: 100%;
}

.antorcha_i {
  margin-left: -120px;
  width: 260px;
}

.centro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: left;
  width: 410px;
  height: 100%;
}

.der {
  width: 200px;
  height: 100%;
}

.antorcha_d {
  margin-left: 60px;
  width: 260px;
}

.logo_i {
  display: block;
  margin: auto;
  margin-top: -160px;
  width: 160px;
}

.text_i {
  margin-top: 10px;
}

.salute_text {
  display: block;
  color: #5e3c00;
  text-align: center;
}

.text_username {
  display: block;
  color: #5e3c00;
  text-align: center;
  font-weight: bold;
}

.subtext_i {
  padding-top: 50px;
  color: #5e3c00;
}

.subtext_username {
  display: block;
  text-align: center;
  color: #5e3c00;
  line-height: 1.4em;
}

.boton_i {
  display: block;
  text-align: center;
  padding-top: 40px;
}

.iniciar_test {
  background-image: url("../../assets/boton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
}

@media (max-height: 720px) {
  .testInicio {
    background: url("../../assets/MapaPapel.png");
    background-position: center;
    background-size: cover;
    width: 810px;
    height: 600px;
    display: flex;
  }

  .antorcha_i {
    margin-left: -90px;
  }

  .antorcha_d {
    margin-left: 90px;
  }

  .logo_i {
    display: block;
    margin: auto;
    margin-top: -40px;
    width: 100px;
  }
}
</style>