<template>
  <div id='lista'>
        <Menubar :model="items" />
        <DataTable :value="lista" :paginator="true" :filters="filters" :rows="6" :selection.sync="sel_persona" selectionMode="single" dataKey="id">
          <template #header>
            <div class="table-header">
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']" placeholder="Global Search" />
                </span>
            </div>
        </template>
          <Column field="names" header="Nombres"></Column>
          <Column field="last_names" header="Apellidos"></Column>
          <Column field="departmentId" header="Escuadrón"></Column>
          <Column field="country_id" header="País"></Column>
          <Column field="job" header="Cargo"></Column>
        </DataTable>


      <Dialog :visible.sync="display" :modal="true">
        ¿Desea deshabilitar este miembro?
        <template #footer>
          <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeModal"/>
          <Button label="Yes" icon="pi pi-check" autofocus @click="confirmarEliminacion"/>
        </template>
      </Dialog>
      <div class="boton_container">
        <button id="boton_addUser" @click="addUser">Agregar Nuevo Integrante</button>
      </div>
  </div>
</template>
<script>
import {Global} from "../../Global.js";
import axios from "axios";
import '../../axiosInterceptor.js';

export default {
  name: 'App',
  components: {

  },
  data(){
    return{
        persona:{
          id:null,
          names:"",
          last_names:"",
          email:"",
          address:"",
          children:"",
          job:"",
          profession:"",
          academic_degree:"",
          bayer_roles:"",
          hobbies:"",
          historical_figure:"",
          fiction_character:"",
        },
        sel_persona:{
          id:null,
          names:"",
          last_names:"",
          email:"",
          address:"",
          children:"",
          job:"",
          profession:"",
          academic_degree:"",
          bayer_roles:"",
          hobbies:"",
          historical_figure:"",
          fiction_character:"",
        },
        displayModal:false,
        display:false,
        personas:null,
        dptos:null,
        paises:null,
        lista:null,
        filters:{},
        items:[
          {
            label:'Editar',
            icon:'pi pi-fw pi-pencil',
            command:()=>{
              this.editarUser();
            }
          },
          {
            label:'Eliminar',
            icon:'pi pi-fw pi-trash',
            command:()=>{
              this.eliminarUser();
            }
          },
          {
            label:'Actualizar',
            icon:'pi pi-fw pi-refresh',
            command:()=>{
              this.actualizar();
            }
          }
        ]
    }
  }, 
  methods:{
    confirmarEdicion(){
      //this.cambiarString();
      let data=JSON.stringify({
        user:this.persona
      });
      axios
      .put(Global.url+'users/'+this.persona.id, data,{headers:{"Content-Type" : "application/json"}})
      .then( (response)=>{
        console.log(response);
        this.displayModal=false;
      })
      .catch( (error)=>{
        console.log(error)
      });
    },
   
    closeModal(){
      this.displayModal=false;
      this.display=false;
    },
    addUser(){
        this.$router.push({ name: 'add_miembro'}).catch(()=>{});
    },
    editarUser(){
      if(this.sel_persona.id==null){
        this.displayModal=false;
        this.$notify({
            type:'warn',
            group: 'foo',
            title: 'Seleccione miembro',
            text: '',         
            });

      }else{
        this.$router.push({ name: 'edit_miembro',params:{id:this.sel_persona.id}}).catch(()=>{});
      }
    },
    eliminarUser(){
      if(this.sel_persona.id==null){
        this.displayModal=false;
        this.$notify({
            type:'warn',
            group: 'foo',
            title: 'Seleccione miembro',
            text: '',         
            });

      }else{
        this.persona.id=this.sel_persona.id;
        this.display=true;
      }
    },
    confirmarEliminacion(){
      let id=this.persona.id
      
      axios
      .post(Global.url+'users/'+id+'/disable')
      .then( (response)=>{
        console.log(response)
        this.display=false;
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
    actualizar(){
      this.traerUsers();    
    },
    traerUsers(){
      axios
        .get(Global.url+'users/get-all')
        .then( (response)=>{
          this.personas=response.data.data;
          this.getDptos();      
        })
        .catch( (error)=>{
          console.log(error)
        })
    },
    getDptos(){
      axios
      .get(Global.url+'departments')
      .then( (response)=>{
        this.dptos=response.data.data;
        this.getCountry();
        
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
    getCountry(){
      axios
      .get(Global.url+'countries')
      .then( (response)=>{
        this.paises=response.data.data;
        this.vincularDptos();
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
    vincularDptos(){
      for(let i in this.personas){
        for(let j in this.dptos){
          if(this.personas[i].departmentId==this.dptos[j].id){
            this.personas[i].departmentId=this.dptos[j].name;
          }
        }
        for(let k in this.paises){
          if(this.personas[i].country_id==this.paises[k].id){
            this.personas[i].country_id=this.paises[k].name;
          }
        }
      }
      this.lista=this.personas;
    },
  
  },
  created(){

  },
  mounted(){
    this.traerUsers();
  }
}
</script>

<style lang="scss" scoped>

#lista{
  //background-color: #472d10;
  width: 100%;
    height: 700px;
}

.boton_container{
    display: block;
  text-align: center;
  margin-top: 50px;
}

#boton_addUser{
    background-color:#302C28;
  width: 310px;
  height: 55px;
  font-size: 20px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  outline: none; 
  border: 0;
  text-align: center;
}
</style>