<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          id="modalTitle"
        >
          <slot name="header">
            <img class="logo_modal"
                src="../../assets/Escuadron.png"
                alt="Logo de Escuadrón Marketing"/>
          </slot>
        </header>
        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
            <p class="text_modal">Hay muchos tesoros por descubrir y equipos por 
            conocer ¿Estás seguro de finalizar tu travesía?</p>
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">                
            <div class="botones_modal_container">
                <button id="boton_modal" type="button" @click="close">
                    Seguir explorando
                </button>
                <button id="boton_modal" type="button" @click="logout">
                    Sí, cerrar
                </button>
            </div>
            <!--<button
              type="button"
              class="btn-green"
              @click="close"
              aria-label="Close modal"
            >
              Close me!
            </button>-->
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'modal',
    methods: {
      close() {
        this.$emit('close');
      },
      logout(){
        localStorage.clear();
      this.$router.push({ name: 'inicio_sesion'}).catch(()=>{});  
      }
    },
  };
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  background: url("../../assets/MapaPapel.png");
  /*box-shadow: 2px 2px 20px 1px;*/
  background-position: center;
  background-size: cover;
  overflow-x: auto;
  display: flex;
  width: 580px;
  height: 430px;
  flex-direction: column;
  justify-content: center;
}

.modal-header,
.modal-footer {
  margin: 0 auto;
  padding: 0 15px 15px;
}

.modal-header {
  padding-top: 25px;
}

.modal-body {
  position: relative;
  padding: 20px 70px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4AAE9B;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.logo_modal{
    width: 230px;
    margin: -20px auto;
    
}

.text_modal{
  text-align: center;
  font-size: 16px;
  color: #5e3c00;
}

.botones_modal_container{
  display: flex;
  margin: 15px 25px;
  width: 90%;
}

#boton_modal{
  width: 240px;
  height: 70px;
  background-image: url("../../assets/boton.svg");
  background-position: center;
  background-position-y: 5px;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  font-weight: bold;
  font-size: 18px;
  color: white;
  cursor: pointer;
  outline: none;
}
</style>