<template>
  <div class="directorio">
    <div class="columna_1">
      <div class="info_seccion">
        <div class="texto_interior">
          <div class="titulo_escuadron text text--big">
            Escuadrón {{ nombre_seccion }}
          </div>
          <div class="descripcion text">
            {{ descripcion_seccion }}
          </div>
        </div>
      </div>
      <div class="lista_personas">
        <img
          class="img_escuadron"
          src="../assets/TikiZanahoria.svg"
          alt="img_escuadron"
        />
        <div class="lista_centro">
          <div
            v-if="lista_trabajadores.length > 1"
            class="titulo_lista text text--big">
            Miembros del equipo
          </div>
          <ul
            class="listado"
            :class="{ 'extra-margin': lista_trabajadores.length === 1 }">
            <li
              class="item_lista"
              v-for="(member, i) in lista_trabajadores"
              :class="{ 'selected': i === selectedUser }"
              :value="member.id"
              :id="member.id"
              :key="i"
              v-on:click="usuarioSeleccionado(member.id, i)"
            >
              {{ member.names }} {{ member.last_names }}
            </li>
          </ul>
        </div>
        <img class="scroll_img" src="../assets/scroll.svg" alt="scroll_img" />
      </div>
      <button class="boton_volver" @click="volverMapa">VOLVER</button>
    </div>
    <div class="columna_2">
      <div class="perfil_persona">
        <div
          v-show="!user_seleccionado"
          class="no-user">
          <img src="../assets/TikiTomate.svg">
          <div class="texto_lista text text--extra-large">Escoge a un miembro del equipo que quieras conocer</div>
        </div>
        <div class="perfil_container" ref="perfil" v-show="user_seleccionado">
          <div class="avatar_container" v-show="!mostrar_galeria">
            <img
              class="marco_perfil"
              src="../assets/marco.png"
              alt="marco_perfil"/>
            <img
              v-show="sin_avatar"
              class="avatar_perfil"
              src="../assets/user.png"
              alt="avatar_perfil"/>
            <div
              v-show="!sin_avatar"
              class="avatar">
              <img
                id="avatar_perfil2"
                alt="avatar_perfil"/>
            </div>
          </div>
          <div class="perfil" v-show="!mostrar_galeria">
            <img
              v-if="this.nacionalidad == 1"
              class="bandera"
              src="../assets/banderas/Colombia.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 2"
              class="bandera"
              src="../assets/banderas/Mexico.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 3"
              class="bandera"
              src="../assets/banderas/Ecuador.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 4"
              class="bandera"
              src="../assets/banderas/Peru.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 5"
              class="bandera"
              src="../assets/banderas/Venezuela.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 6"
              class="bandera"
              src="../assets/banderas/Guatemala.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 7"
              class="bandera"
              src="../assets/banderas/Honduras.png"
              alt="bandera"
            />
            <img
              v-else-if="this.nacionalidad == 8"
              class="bandera"
              src="../assets/banderas/costarica.png"
              alt="bandera"
            />
            <div class="nombre_perfil text text--enormous">{{ nombre }}</div>
            <div class="nombre_perfil text text--enormous">{{ apellido }}</div>
            <div class="cargo_perfil text text--big">{{ cargo }}</div>

            <div class="titulos text text--medium">País de residencia</div>
            <div class="contenido_seccion text text--medium">{{ pais ? pais : "N/A" }}</div>
            <div class="titulos text text--medium">Profesión</div>
            <div class="contenido_seccion text text--medium">{{ profesion ? profesion : "N/A" }}</div>
            <div class="titulos text text--medium">Hijos</div>
            <div class="contenido_seccion text text--medium">{{ hijos ? hijos : "N/A" }}</div>
            <div class="titulos text text--medium">Postgrados</div>
            <div class="contenido_seccion text text--medium">{{ postgrados ? postgrados : "N/A" }}</div>
            <div class="titulos text text--medium">Últimos trabajos</div>
            <div class="contenido_seccion text text--medium">{{ u_trabajos ? u_trabajos : "N/A" }}</div>
            <div class="titulos text text--medium">Historial en Bayer</div>
            <div class="contenido_seccion text text--medium">{{ historial ? historial : "N/A" }}</div>
            <div class="titulos text text--medium">Hobbies</div>
            <div class="contenido_seccion text text--medium">{{ hobbies ? hobbies : "N/A" }}</div>
            <div class="titulos text text--medium">Personaje histórico favorito</div>
            <div class="contenido_seccion text text--medium">{{ personaje_his ? personaje_his : "N/A" }}</div>
            <div class="titulos text text--medium">Personaje de ficción favorito</div>
            <div class="contenido_seccion text text--medium">{{ personaje_fic ? personaje_fic : "N/A" }}</div>
            <div class="titulos text text--medium">Deportes</div>
            <div class="contenido_seccion text text--medium">{{ deportes ? deportes : "N/A" }}</div>
            <div class="titulos text text--medium">Instrumentos</div>
            <div class="contenido_seccion text text--medium">{{ instrumentos ? instrumentos : "N/A" }}</div>
          </div>
          <div class="perfil_img" v-show="mostrar_galeria">
            <img v-for="(imagen, i) in imagenes_" :key="i"
                class="foto_perfil"
                v-bind:src="dir_imagenes[i]"
                alt="foto_perfil"
              />
          </div>
        </div>
        <div class="columna_perfil" v-show="user_seleccionado">
          <img
            class="img_perfil"
            src="../assets/img_perfil.svg"
            alt="img_perfil"
            @click="mostrar_info"
          />
          <img
            class="scroll_img_perfil"
            src="../assets/scroll.svg"
            alt="scroll_img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Global } from "../Global.js";
import _findIndex from 'lodash/findIndex';
import axios from "axios";
import "../axiosInterceptor.js";
//import Perfil from './Perfil.vue'
export default {
  name: "App",
  components: {
    //Perfil
  },
  data() {
    return {
      id: "",
      avatar: "",
      nacionalidad: "",
      pais: "",
      nombre: "",
      apellido: "",
      cargo: "",
      profesion: "",
      hijos: "",
      experiencia: "",
      postgrados: "",
      u_trabajos: "",
      historial: "",
      hobbies: "",
      personaje_his: "",
      personaje_fic: "",
      deportes: "",
      instrumentos: "",
      mostrar_galeria: false,
      imagenes: ["marco.png", "img2.png", "img3.png", "img4.png", "img5.png"],
      nombre_seccion: "",
      descripcion_seccion: "",
      lista_trabajadores: [],
      user_seleccionado: false,
      usuario: null,
      imagenes_: null,
      ruta_generica: "http://escuadronmarketing.com/api/",
      ruta_completa: "",
      rutas: [],
      sin_avatar: false,
      dir_imagenes: [],
      selectedUser: -1
    };
  },
  methods: {
    volverMapa() {
      this.$router.push({ name: 'mapa'}).catch(()=>{});
    },
    mostrar_info() {
      this.mostrar_galeria = !this.mostrar_galeria;
    },
    cargar_imagenes() {
      let long = this.imagenes.length;
      if (long > 0) {
        for (let i = 0; i < long; i++) {
          let ruta = "../assets/" + this.imagenes[i];
          let iterador = i + 1;
          var objeto = document.getElementById("img" + iterador);
          objeto.style.background = "url('" + ruta + "');";
        }
      }
    },
    usuarioSeleccionado: function (idEvent, i) {
      this.selectedUser = i;
      this.mostrar_galeria = false;
      let id = idEvent;
      this.usuario = id;
      for (let i in this.lista_trabajadores) {
        if (id == this.lista_trabajadores[i].id) {
          this.usuario = this.lista_trabajadores[i];
          this.avatar = this.usuario.avatar;
        }
      }
      this.user_seleccionado = true;
      this.mostrar_datos();
      this.$nextTick(() => {
        this.$refs.perfil.scrollTop = 0;
      });
    },
    mostrar_datos() {
      this.id = this.usuario.id;
      //this.avatar=this.usuario.avatar;
      if (this.usuario.avatar === null || this.usuario.avatar == 0) {
        this.sin_avatar = true;
      } else {
        this.sin_avatar = false;
        let ruta = this.usuario.avatar.substr(7);
        document.getElementById("avatar_perfil2").src =
          this.ruta_generica + ruta;
      }
      this.nacionalidad = this.usuario.country_id;
      this.pais = this.usuario.country_residence;
      this.nombre = this.usuario.names;
      this.apellido = this.usuario.last_names;
      this.cargo = this.usuario.job;
      this.profesion = this.usuario.profession;
      this.hijos = this.usuario.children;
      this.postgrados = this.usuario.academic_degree;
      this.u_trabajos = this.usuario.previous_companies;
      this.historial = this.usuario.bayer_roles;
      this.hobbies = this.usuario.hobbies;
      this.personaje_his = this.usuario.historical_figure;
      this.personaje_fic = this.usuario.fiction_character;
      this.deportes = this.usuario.sports;
      this.instrumentos = this.usuario.musical_instrument;
      this.getImagenes();
    },
    getUsers() {
      axios
        .get(Global.url + "users/get-all")
        .then((response) => {
          let id = localStorage.getItem("id_dpto");
          this.lista_trabajadores = response.data.data.filter((t) => t.departmentId == Number(id));
          console.log(this.lista_trabajadores)
          if (this.lista_trabajadores.length === 1) {
            this.usuarioSeleccionado(this.lista_trabajadores[0].id, 0);
          } else {
            let leader;
            let leader2 = null;
            switch (id) {
              case "2":
                leader = _findIndex(this.lista_trabajadores, { id: 5 });
                break;
              case "3":
                leader = _findIndex(this.lista_trabajadores, { id: 9 });
                break;
              case "4":
                leader = _findIndex(this.lista_trabajadores, { id: 11 });
                break;
              case "5":
                leader = _findIndex(this.lista_trabajadores, { id: 11 });
                break;
              case "6":
                leader = 0;
                break;
              case "7":
                leader = _findIndex(this.lista_trabajadores, { id: 27 });
                break;
              case "8":
                leader = _findIndex(this.lista_trabajadores, { id: 28 });
                leader2 = _findIndex(this.lista_trabajadores, { id: 43 });
                break;
              case "9":
                leader = _findIndex(this.lista_trabajadores, { id: 41 });
                break;
              default:
                break;
            }
            if (leader2) {
              [this.lista_trabajadores[0], this.lista_trabajadores[leader]] = [this.lista_trabajadores[leader], this.lista_trabajadores[0]];
              [this.lista_trabajadores[1], this.lista_trabajadores[leader2]] = [this.lista_trabajadores[leader2], this.lista_trabajadores[1]];
            }
            else if(!leader2 && id!=10){
              [this.lista_trabajadores[0], this.lista_trabajadores[leader]] = [this.lista_trabajadores[leader], this.lista_trabajadores[0]];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getImagenes() {
      axios
        .get(Global.url + "images/" + this.id)
        .then((response) => {
          this.imagenes_ = response.data.data;
          this.direccionImagenes();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    direccionImagenes() {
      let url = [];
      for (let i in this.imagenes_) {
        url.push(this.ruta_generica + this.imagenes_[i].image_url.substr(7));
      }
      this.dir_imagenes = url;
    },
  },
  mounted() {
    this.nombre_seccion = localStorage.getItem("name_dpto");
    this.descripcion_seccion = localStorage.getItem("des_dpto");
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "./../scss/styles.scss";

.directorio {
  display: flex;
  align-items: center;
  background: url("../assets/wallpaper_main.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.columna_1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
}

.info_seccion {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 280px;
  background: url("../assets/papel_descripcion.png");
}

.texto_interior {
  overflow: auto;
  padding-bottom: 20px;
  width: 80%;
  height: 80%;
}

.titulo_escuadron {
  padding-top: 20px;
  text-align: center;
  color: #5e3c00;
  font-weight: bold;
}

.descripcion {
  margin-top: 20px;
  color: #5e3c00;
}

.lista_personas {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: url("../assets/papel_lista.png");
  width: 400px;
  height: 370px;
}

.img_escuadron {
  position: absolute;
  margin-top: -50px;
  margin-left: -200px;
  width: 80px;
  transform: rotate(-15deg);
}

.lista_centro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.titulo_lista {
  margin-top: 50px;
  color: #5e3c00;
  font-weight: bold;
}

.no-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  img {
    width: 90px;
  }
}

.texto_lista {
  margin-top: 30px;
  color: #5e3c00;
}

.scroll_img {
  position: absolute;
  margin-top: 130px;
  margin-left: 155px;
}

.listado {
  margin-top: 15px;
  margin-left: 50px;
  list-style: none;
  overflow: auto;
  width: 72%;
  height: 57%;
  align-self: flex-start;
}

.extra-margin {
  margin-top: 50px;
}

.item_lista {
  margin: 10px 10px 15px 10px;
  padding: 5px 15px;
  color: #5e3c00;
  font-size: map-get($font-sizes, "big");
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  &:first-of-type {
    margin-top: 0;
  }
}

.item_lista:hover {
  background: #5e3c00;
  color: white;
}

.selected {
  background: #5e3c00;
  color: white;
}

.columna_2 {
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
}

.perfil_persona {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 669px;
  height: 780px;
  background: url("../assets/papel_perfil.png");
}

.perfil_container {
  display: block;
  height: 630px;
  width: 470px;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-right: 30px;
}

.avatar_container {
  padding-top: 20px;
  padding-right: 40px;
  position: relative;
  text-align: center;
}

.avatar {
  position: absolute;
  width: 97px;
  height: 97px;
  margin-top: -140px;
  margin-left: 145px;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  #avatar_perfil2 {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.marco_perfil {
  position: relative;
  z-index: 1;
}

.perfil {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
}

.avatar_perfil {
  position: absolute;
  margin-top: 21px;
  margin-left: -119px;
  width: 97px;
  height: 97px;
  z-index: 0;
}

.bandera {
  align-self: center;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.nombre_perfil {
  align-self: center;
  color: #5e3c00;
  font-weight: bold;
}

.cargo_perfil {
  align-self: center;
  font-weight: bold;
  color: #D96D01;
  padding-top: 3px;
}

.titulos {
  text-align: left;
  color: #5e3c00;
  margin-top: 25px;
  margin-left: 25px;
}

.contenido_seccion {
  text-align: left;
  color: black;
  margin: 0 25px;
}

.fila {
  display: flex;
  width: 90%;
  height: 150px;
  background: rgba(0, 0, 0, 0.15);
  margin: 30px 20px;
  border-radius: 10px;
}

.columna_perfil {
  position: absolute;
  margin-top: -500px;
  margin-left: 490px;
}

.img_perfil {
  position: absolute;
  cursor: pointer;
}

.scroll_img_perfil {
  position: absolute;
  margin-top: 500px;
  margin-left: 5px;
}

.boton_volver {
  background-image: url("../assets/boton.svg");
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 5px;
  width: 240px;
  height: 70px;
  font-size: map-get($font-sizes, 'extra-large');
  color: white;
  cursor: pointer;
  outline: none;
  border: 0;
  margin-top: 10px;
}

.perfil_img {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 15px;
  width: 450px;
  overflow: auto;
}

.foto_perfil {
  margin-top: 20px;
  margin-left: 20px;
  width: 200px;
}


@media screen and (max-width: 1280px) {
  .info_seccion {
    height: 250px;
  }

  .mapa_principal {
    height: 350px;
  }

  .perfil_persona {
    width: 670px;
    height: 670px;
  }

  .columna_2 {
    align-items: flex-start;
  }

  .perfil_container {
    margin-top: 20px;
    height: 610px;
  }
}
</style>