<template>
  <div id='add_user'>
      <div class="volver">
          <img id='volver_img' src="../../assets/volver.png" alt="volver" @click="volver">
          <p class="volver_text">volver</p>
      </div>
      <div class="formulario">
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <p class="titulo_seccion">Informacion Personal</p>
      <p class="subtitulo">Nombres</p>
      <ValidationProvider name="nombre" rules="required">
      <div slot-scope="{ errors }">
        <input  class="input_add" v-model="persona.names">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Apellidos</p>
     <ValidationProvider name="apellido" rules="required">
      <div slot-scope="{errors}">
        <input class="input_add" v-model="persona.last_names">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Nacionalidad</p>
    <select class="select_form" id="pais_select">
      <option v-for="(pais,i) in paises" :value="pais.id" :id="pais.id" :key="i">{{pais.name}}</option>
    </select>
    <p class="subtitulo">Pais de Residencia</p>
    <ValidationProvider name="residence" rules="required">
      <div slot-scope="{errors}">
        <input class="input_add" v-model="persona.country_residence">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Fecha de Nacimiento</p>
    <input type="date" class="input_add" v-model="persona.date_birth">
    <p class="subtitulo">Teléfono</p>
    <input class="input_add" v-model="persona.phone">

     <p class="titulo_seccion">Información Familiar</p>
      <p class="subtitulo">Hijos</p>
      <ValidationProvider name="children" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.children"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
      <p class="titulo_seccion">Información Laboral</p>
      <p class="subtitulo">Cargo en la empresa</p>
      <ValidationProvider name="cargo" rules="required">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.job">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
      <p class="subtitulo">Departamento</p>
      <select class="select_form" id="dpto_select">
      <option v-for="(dpto,i) in dptos" :value="dpto.id" :id="dpto.id" :key="i">{{dpto.name}}</option>
    </select>
       <p class="subtitulo">Profesión</p>
       <ValidationProvider name="profession" rules="required">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.profession">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Roles en Bayer</p>
    <ValidationProvider name="bayer_roles" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.bayer_roles"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Experiencia Laboral</p>
    <textarea class="area" v-model="persona.previous_companies"></textarea>
    <p class="subtitulo">¿A trabajado fuera del país?</p>
    <textarea class="area" v-model="persona.outside_country"></textarea>
    <p class="titulo_seccion">Estudios</p>
    <p class="subtitulo">Grado Académico</p>
    <ValidationProvider name="academic" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.academic_degree"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="titulo_seccion">Hobbies</p>
    <p class="subtitulo">Hobbies</p>
    <textarea class="area" v-model="persona.hobbies">academic_degree"></textarea>
    <p class="subtitulo">Personaje ficción favorito</p>
    <input class="input_add" v-model="persona.fiction_character">
    <p class="subtitulo">Personaje histórico favorito</p>
    <input class="input_add" v-model="persona.historical_figure">
    <p class="subtitulo">Instrumentos Musicales</p>
    <input class="input_add" v-model="persona.musical_instrument">
    <p class="subtitulo">Deportes</p>
    <input class="input_add" v-model="persona.sports">
    <p class="titulo_seccion">Datos de Autenticación</p>
    <p class="subtitulo">Correo</p>
    <ValidationProvider name="correo" rules="required|email">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.email">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Contraseña</p>
    <ValidationProvider name="password" rules="required|min|number|char">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.password">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">¿Es administrador?</p>
    <select class="select_form" id="admin_select">
      <option value=false>no</option>
      <option value=true>si</option>
    </select>
      <button id="submit_boton" class="añadir" type="submit">AÑADIR</button>
    </form>
  </ValidationObserver>
      </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import {Global} from "../../Global.js";
import axios from "axios";
import '../../axiosInterceptor.js';


extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
 
// Add the email rule
extend('email', {
  ...email,
  message: 'Ingrese un correo valido'
});
//add min characters rule
extend('min', {message:"La contraseña debe tener minimo 6 caracteres",
validate: value => {
  return value.length >= 8;
}});

extend('number',{message:"La contraseña debe tener por lo menos un número",
validate:value=>{
  let cont=0
  let numbers=[0,1,2,3,4,5,6,7,8,9]
  for(let i in value){
    for(let j in numbers){
      if(value[i]==numbers[j]){
        cont=cont+1;
      }
    }
  }
  if(cont>=1){
    return true
  }else{
    return false
  }
}})

extend('char',{message:"La contraseña debe tener por lo menos una letra",
validate:value=>{
  let cont=0
  let letras=['a','b','c','d','e','f','g','h','i','j','k',
              'l','m','n','ñ','o','p','q','r','s','t',
              'u','v','w','x','y','z','A','B','C','D',
              'E','F','G','H','I','J','K','L','M','N',
              'Ñ','O','P','Q','R','S','T','U','V','W',
              'X','Y','Z']
  for(let i in value){
    for(let j in letras){
      if(value[i]==letras[j]){
        cont=cont+1;
      }
    }
  }
  if(cont>=1){
    return true
  }else{
    return false
  }            
}})

export default {
  name: 'App',
  components: {
    ValidationProvider,
    ValidationObserver

  },
  data(){
    return{
        persona:{
          names:null,
          last_names:null,
          country_id:null,
          children:null,
          job:null,
          profession:null,
          bayer_roles:null,
          previous_companies:null,
          country_residence:null,
          department_id:null,
          date_birth:null,
          phone:null,
          outside_country:null,
          academic_degree:null,
          hobbies:null,
          fiction_character:null,
          historical_figure:null,
          musical_instrument:null,
          sports:null,
          email:null,
          password:null,
          is_admin:null
        },
        paises:null,
        dptos:null,
        foto_perfil:null,
    }
  },
  methods:{
    volver(){
        this.$router.push({ name: 'menu_admin'}).catch(()=>{});
    },
    obtenerIDpais(){
      let value=document.getElementById('pais_select').value;
      this.persona.country_id=Number(value)
    },
    obtenerIDdptos(){
      let value=document.getElementById('dpto_select').value;
      this.persona.department_id=Number(value)
    },
    obtenerAdminState(){
      let value=document.getElementById('admin_select').value;
      if(value=="true"){
        this.persona.is_admin=true
      }else{
        this.persona.is_admin=false
      }
    },
    formatoDate(){
      if (this.persona.date_birth==null) {
        return
      } else {
        let date=this.persona.date_birth+'T12:00:00Z'
        this.persona.date_birth=date
      }
    },
    reiniciarData(){
      this.persona.names=null;
      this.persona.last_names=null;
      this.persona.country_id=null;
      this.persona.children=null;
      this.persona.job=null;
      this.persona.profession=null;
      this.persona.bayer_roles=null;
      this.persona.previous_companies=null;
      this.persona.country_residence=null;
      this.persona.department_id=null;
      this.persona.date_birth=null;
      this.persona.phone=null;
      this.persona.outside_country=null;
      this.persona.academic_degree=null;
      this.persona.hobbies=null;
      this.persona.fiction_character=null;
      this.persona.historical_figure=null;
      this.persona.musical_instrument=null;
      this.persona.sports=null;
      this.persona.email=null;
      this.persona.password=null;
      this.persona.is_admin=null;
    },
    validarform(){
      if(this.persona.date_birth==""){this.persona.date_birth=null}
      if(this.persona.phone==""){this.persona.phone=null}
      if(this.persona.previous_companies==""){this.persona.previous_companies=null}
      if(this.persona.outside_country==""){this.persona.outside_country=null}
      if(this.persona.hobbies==""){this.persona.hobbies=null}
      if(this.persona.fiction_character==""){this.persona.fiction_character=null}
      if(this.persona.historical_figure==""){this.persona.historical_figure=null}
      if(this.persona.musical_instrument==""){this.persona.musical_instrument=null}
      if(this.persona.sports==""){this.persona.sports=null}
    },
    createUser(){
      this.obtenerIDpais();
      this.obtenerIDdptos();
      this.obtenerAdminState();
      this.formatoDate();
      this.validarform();
      let data=JSON.stringify({
        user:this.persona
      });
      axios
      .post(Global.url+'users',data,{headers:{"Content-Type" : "application/json"}})
      .then( (response)=>{
        console.log(response);
        this.reiniciarData();
        //let id_creado=response.data.data.id;
        this.$router.push({name:'add_image',params:{id:response.data.data.id}}).catch(()=>{});
        this.$notify({
            type:'success',
            group: 'foo',
            title: 'Usuario Añadido',
            text: '',
                        
          });
      })
      .catch ( (error)=>{
        console.log(error);
        this.$notify({
            type:'error',
            group: 'foo',
            title: 'Error',
            text: '',
                        
          });
          this.persona.date_birth=null
      })
    },
    onSubmit(){
      this.createUser()
    },
    getPaises(){
      axios
      .get(Global.url+'countries')
      .then( (response)=>{
        this.paises=response.data.data;
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
    getDptos(){
      axios
      .get(Global.url+'departments')
      .then( (response)=>{
        this.dptos=response.data.data
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
  },
  mounted() {
    this.getPaises();
    this.getDptos();
  }
}
</script>

<style lang="scss" scoped>

#add_user{
  background-color: #C7B9A9;
  min-height: 300vh;
  height: 100%;
}
 .volver{
    float: left;
  width: 10%;
  height: 100%;
  display: block;
  //background-color: sandybrown;
 }

 .formulario{
   padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    height: 100%;
    display: block;
    //background-color: gray;
 }

#volver_img{
    width: 50px;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.volver_text{
  margin-left: 30px;
  font-weight: bold;  
}

.titulo_seccion{
  margin-top: 20px;
  font-size: 22px;
  color: #302C28;
}
  
.subtitulo{
  margin-top: 10px;
  font-size: 18px;
}

.input_add{
  margin-top: 5px;
  background: white;
  width: 370px;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  //color:rgb(182, 159, 117);
  font-size: 15px;
  outline: none;
  border: 0;
}

.input1_add:focus{
  border: 3px solid #302C28;
}

.select_form{
  margin-top: 5px;
  width: 370px;
  height: 50px;
  border-radius: 4px;
  background:rgb(182, 159, 117);
  color: white;
  border: 0;
  outline: none;
  cursor: pointer;
}

.area{
  border: 0;
  margin-top: 5px;
  width: 370px;
  height: 200px;
  border-radius: 4px;
  padding:5px 5px;
  outline: none;
}

.foto_perfil{
  margin-top: 30px;
  background: rgb(182, 159, 117);
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  padding-top: 15px;
  //color:rgb(182, 159, 117);
  font-size: 15px;
  outline: none;
  border: 0;
}

.añadir{
  margin-top: 30px;
  background: #302C28;
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color:white;
  font-size: 15px;
  outline: none;
  border: 0;
  margin-bottom: 50px;
}

.errors{
  color: rgb(211, 31, 31);
}
</style>