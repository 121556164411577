<template>
  <div id='menu_admin'>
      <div class="izq">
          <img id='logo_admin' src="../../assets/Administrador.png" alt="logo_admin">
      </div>
      <div class="center">
        <div class="table_container">
            <ListaUsers></ListaUsers>
        </div>
      
      </div>
      <div class="der">
          <p class="log_out" @click="goLogin">Cerrar Sesión</p>
     </div>
      
  </div>
</template>
<script>
import ListaUsers from './ListaUsers.vue'


export default {
  name: 'App',
  components: {
      ListaUsers
  },
  data(){
    return{
        state_toggle:false
    }
  },
  
  
  methods:{
    goLogin(){
        this.$router.push({ name: 'admin_login'}).catch(()=>{});
        localStorage.clear()
    },
  },
}
</script>

<style lang="scss" scoped>

#menu_admin{
  background-color: #C7B9A9;
  min-height: 100vh;
}

.izq{
   float: left;
  height: 100%;
  width: 15%;
  display: block;
  //background-color: thistle
}

.center{
    float: left;
    height: 100%;
    width: 70%;
    //background-color: red
}

.der{
    float: left;
  height: 100%;
  width: 15%;
  display: block;
  //background-color: yellow;
}

#toggle_container{
    padding-top: 20px;
    width: 100%;
    //background-color: yellow;
    display: block;
    text-align: center;
}
 #logo_admin{
     margin: 50px 70px;
     width: 118px;
     height: 98px;
 }

.log_out{
    margin-top: 20px;
    margin-left: 70px;
    cursor: pointer;
    font-weight: bold;
}

#boton_add{
  background-color:#302C28;
  width: 300px;
  height: 55px;
  font-size: 17px;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  outline: none; 
  border: 0;
  text-align: center;  
}

.table_container{
    margin-top: 50px;
    width: 100%;
    height: 700px;
    //background-color: royalblue;
}

@media (max-width: 1430px){
  #menu_admin{
  background-color: #C7B9A9;
  min-height: 130vh;
}
}
</style>
