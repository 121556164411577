<template>
  <div id='edit_user'>
      <div class="volver">
          <img id='volver_img' src="../../assets/volver.png" alt="volver" @click="volver">
          <p class="volver_text">volver</p>
      </div>
      <div class="formulario">
    <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <p class="titulo_seccion">Informacion Personal</p>
      <p class="subtitulo">Nombres</p>
      <ValidationProvider name="nombre" rules="required">
      <div slot-scope="{ errors }">
        <input  class="input_add" v-model="persona.names">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Apellidos</p>
     <ValidationProvider name="apellido" rules="required">
      <div slot-scope="{errors}">
        <input class="input_add" v-model="persona.last_names">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Nacionalidad</p>
    <select class="select_form" id="pais_select">
      <option v-for="(pais,i) in paises" :value="pais.id" :id="pais.id" :key="i">{{pais.name}}</option>
    </select>
    <p class="subtitulo">Pais de Residencia</p>
    <ValidationProvider name="residence" rules="required">
      <div slot-scope="{errors}">
        <input class="input_add" v-model="persona.country_residence">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Teléfono</p>
    <input class="input_add" v-model="persona.phone">

     <p class="titulo_seccion">Información Familiar</p>
      <p class="subtitulo">Hijos</p>
      <ValidationProvider name="children" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.children"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
      <p class="titulo_seccion">Información Laboral</p>
      <p class="subtitulo">Cargo en la empresa</p>
      <ValidationProvider name="cargo" rules="required">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.job">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Departamento</p>
    <select class="select_form" id="dpto_select">
      <option v-for="(dpto,i) in dptos" :value="dpto.id" :id="dpto.id" :key="i">{{dpto.name}}</option>
    </select>
       <p class="subtitulo">Profesión</p>
       <ValidationProvider name="profession" rules="required">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.profession">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Roles en Bayer</p>
    <ValidationProvider name="bayer_roles" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.bayer_roles"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">Experiencia Laboral</p>
    <textarea class="area" v-model="persona.previous_companies"></textarea>
    <p class="subtitulo">¿A trabajado fuera del país?</p>
    <textarea class="area" v-model="persona.outside_country"></textarea>
    <p class="titulo_seccion">Estudios</p>
    <p class="subtitulo">Grado Académico</p>
    <ValidationProvider name="academic" rules="required">
      <div slot-scope="{ errors }">
        <textarea class="area" v-model="persona.academic_degree"></textarea>
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="titulo_seccion">Hobbies</p>
    <p class="subtitulo">Hobbies</p>
    <textarea class="area" v-model="persona.hobbies">academic_degree"></textarea>
    <p class="subtitulo">Personaje ficción favorito</p>
    <input class="input_add" v-model="persona.fiction_character">
    <p class="subtitulo">Personaje histórico favorito</p>
    <input class="input_add" v-model="persona.historical_figure">
    <p class="subtitulo">Instrumentos Musicales</p>
    <input class="input_add" v-model="persona.musical_instrument">
    <p class="subtitulo">Deportes</p>
    <input class="input_add" v-model="persona.sports">
    <p class="titulo_seccion">Datos de Autenticación</p>
    <p class="subtitulo">Correo</p>
    <ValidationProvider name="correo" rules="required|email">
      <div slot-scope="{ errors }">
        <input class="input_add" v-model="persona.email">
        <p class="errors">{{ errors[0] }}</p>
      </div>
    </ValidationProvider>
    <p class="subtitulo">¿Es administrador?</p>
    <select class="select_form" id="admin_select">
      <option value=false>no</option>
      <option value=true>si</option>
    </select>
      <button id="submit_boton" class="añadir" type="submit">APLICAR CAMBIOS</button>
    </form>
  </ValidationObserver>
    <button class="boton_fotos" @click="editarFotos">EDITAR FOTOS</button>
      </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
import {Global} from "../../Global.js";
import axios from "axios";
import '../../axiosInterceptor.js';


extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
 
// Add the email rule
extend('email', {
  ...email,
  message: 'Ingrese un correo valido'
});
//add min characters rule
extend('min', {message:"La contraseña debe tener minimo 6 caracteres",
validate: value => {
  return value.length >= 8;
}});

extend('number',{message:"La contraseña debe tener por lo menos un número",
validate:value=>{
  let cont=0
  let numbers=[0,1,2,3,4,5,6,7,8,9]
  for(let i in value){
    for(let j in numbers){
      if(value[i]==numbers[j]){
        cont=cont+1;
      }
    }
  }
  if(cont>=1){
    return true
  }else{
    return false
  }
}})

extend('char',{message:"La contraseña debe tener por lo menos una letra",
validate:value=>{
  let cont=0
  let letras=['a','b','c','d','e','f','g','h','i','j','k',
              'l','m','n','ñ','o','p','q','r','s','t',
              'u','v','w','x','y','z','A','B','C','D',
              'E','F','G','H','I','J','K','L','M','N',
              'Ñ','O','P','Q','R','S','T','U','V','W',
              'X','Y','Z']
  for(let i in value){
    for(let j in letras){
      if(value[i]==letras[j]){
        cont=cont+1;
      }
    }
  }
  if(cont>=1){
    return true
  }else{
    return false
  }            
}})

export default {
  name: 'App',
  components: {
    ValidationProvider,
    ValidationObserver

  },
  data(){
    return{
        persona:{
          names:null,
          last_names:null,
          children:null,
          job:null,
          profession:null,
          bayer_roles:null,
          previous_companies:null,
          country_residence:null,
          phone:null,
          department_id:null,
          outside_country:null,
          academic_degree:null,
          hobbies:null,
          email:null,
          fiction_character:null,
          historical_figure:null,
          musical_instrument:null,
          sports:null,
          is_admin:null,
          country_id:null,
        },
        selected_persona:null,
        personas:null,
        responseid:null,
        paises:null,
        dptos:null,
    }
  },
  methods:{
      volver(){
        this.$router.push({ name: 'menu_admin'}).catch(()=>{});
    },
    onSubmit(){
      this.editUser()
    },
    obtenerAdminState(){
      let value=document.getElementById('admin_select').value;
      if(value=="true"){
        this.persona.is_admin=true
      }else{
        this.persona.is_admin=false
      }
    },
    validarform(){
      if(this.persona.phone==""){this.persona.phone=null}
      if(this.persona.previous_companies==""){this.persona.previous_companies=null}
      if(this.persona.outside_country==""){this.persona.outside_country=null}
      if(this.persona.hobbies==""){this.persona.hobbies=null}
      if(this.persona.fiction_character==""){this.persona.fiction_character=null}
      if(this.persona.historical_figure==""){this.persona.historical_figure=null}
      if(this.persona.musical_instrument==""){this.persona.musical_instrument=null}
      if(this.persona.sports==""){this.persona.sports=null}
    },
    reiniciarData(){
        this.names=null;
        this.last_names=null;
        this.children=null;
        this.job=null;
        this.profession=null;
        this.bayer_roles=null;
        this.previous_companies=null;
        this.country_residence=null;
        this.phone=null;
        this.outside_country=null;
        this.academic_degree=null;
        this.hobbies=null;
        this.fiction_character=null;
        this.historical_figure=null;
        this.musical_instrument=null;
        this.sports=null,
        this.is_admin=null,
        this.country_id=null,
        this.department_id=null,
        this.email=null
    },
    editUser(){
      this.obtenerIDpais();
      this.obtenerIDdptos();
        this.obtenerAdminState();
        this.validarform();
        let data=JSON.stringify({
            user:this.persona
        });
        //console.log(data)
        axios
        .put(Global.url+'users/'+this.responseid, data,{headers:{"Content-Type" : "application/json"}})
        .then( (response)=>{
            console.log(response)
            this.reiniciarData();
            this.$notify({
            type:'success',
            group: 'foo',
            title: 'Usuario Editado',
            text: '',          
          });
          this.$router.push({ name: 'menu_admin'}).catch(()=>{});
        })
        .catch( (error)=>{
            console.log(error)
            this.$notify({
            type:'error',
            group: 'foo',
            title: 'Error',
            text: '',
                        
          });
        });
    },
    llenarForm(){
        for(let i in this.personas){
            if(this.personas[i].id==this.responseid){
                this.selected_persona=this.personas[i]
            }
        }
        this.persona.names=this.selected_persona.names;
        this.persona.last_names=this.selected_persona.last_names;
        this.persona.children=this.selected_persona.children;
        this.persona.job=this.selected_persona.job;
        this.persona.profession=this.selected_persona.profession;
        this.persona.bayer_roles=this.selected_persona.bayer_roles;
        this.persona.previous_companies=this.selected_persona.previous_companies;
        this.persona.country_residence=this.selected_persona.country_residence;
        this.persona.phone=this.selected_persona.phone;
        this.persona.outside_country=this.selected_persona.outside_country;
        this.persona.academic_degree=this.selected_persona.academic_degree;
        this.persona.hobbies=this.selected_persona.hobbies;
        this.persona.fiction_character=this.selected_persona.fiction_character;
        this.persona.historical_figure=this.selected_persona.historical_figure;
        this.persona.musical_instrument=this.selected_persona.musical_instrument;
        this.persona.sports=this.selected_persona.sports;
        this.persona.is_admin=this.selected_persona.is_admin;
        this.persona.country_id=this.selected_persona.country_id;
        this.persona.department_id=this.selected_persona.department_id;
        this.persona.email=this.selected_persona.email;
        console.log(this.persona.country_id)
    },
    editarFotos(){
        this.$router.push({name:'add_image',params:{id:this.responseid}}).catch(()=>{});
    },
    traerUsers(){
      axios
        .get(Global.url+'users/get-all')
        .then( (response)=>{
          this.personas=response.data.data;
          this.llenarForm();
          this.getPaises();
          this.getDptos();     
        })
        .catch( (error)=>{
          console.log(error)
        })
    },
    getDptos(){
      axios
      .get(Global.url+'departments')
      .then( (response)=>{
        let dptos=response.data.data
        for(let i in dptos){
          if(dptos[i].id==this.persona.department_id){
            let temp=dptos[i];
            dptos[i]=dptos[0];
            dptos[0]=temp;
          }
        }
        this.dptos=dptos;
        console.log(dptos)
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
  
    getPaises(){
      axios
      .get(Global.url+'countries')
      .then( (response)=>{
        let paises=response.data.data;
        for(let i in paises){
          if(paises[i].id == this.persona.country_id){
            let temp=paises[i];
            paises[i]=paises[0];
            paises[0]=temp
          }
        }
        this.paises=paises
        console.log(paises)
      })
      .catch( (error)=>{
        console.log(error)
      })
    },
    obtenerIDpais(){
      let value=document.getElementById('pais_select').value;
      this.persona.country_id=Number(value)
    },
    obtenerIDdptos(){
      let value=document.getElementById('dpto_select').value;
      this.persona.department_id=Number(value)
    },
  },
  mounted() {
    this.responseid=this.$route.params.id
    this.traerUsers();
    
  }
}
</script>

<style lang="scss" scoped>
#edit_user{
  background-color: #C7B9A9;
  min-height: 300vh;
  height: 100%;
}

.volver{
    float: left;
    width: 10%;
    height: 100%;
    display: block;
  //background-color: sandybrown;
 }

 .volver_text{
  margin-left: 30px;
  font-weight: bold;  
}

.formulario{
   padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    height: 100%;
    display: block;
    //background-color: gray;
 }

 #volver_img{
    width: 50px;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.titulo_seccion{
  margin-top: 20px;
  font-size: 22px;
  color: #302C28;
}

.subtitulo{
  margin-top: 10px;
  font-size: 18px;
}

.input_add{
  margin-top: 5px;
  background: white;
  width: 370px;
  height: 50px;
  padding: 12px 20px;
  border-radius: 4px;
  //color:rgb(182, 159, 117);
  font-size: 15px;
  outline: none;
  border: 0;
}

.input1_add:focus{
  border: 3px solid #302C28;
}

.select_form{
  margin-top: 5px;
  width: 370px;
  height: 50px;
  border-radius: 4px;
  background:rgb(182, 159, 117);
  color: white;
  border: 0;
  outline: none;
  cursor: pointer;
}

.area{
  border: 0;
  margin-top: 5px;
  width: 370px;
  height: 200px;
  border-radius: 4px;
  padding:5px 5px;
  outline: none;
}

.foto_perfil{
  margin-top: 30px;
  background: rgb(182, 159, 117);
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  padding-top: 15px;
  //color:rgb(182, 159, 117);
  font-size: 15px;
  outline: none;
  border: 0;
}

.añadir{
  margin-top: 30px;
  background: #302C28;
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color:white;
  font-size: 15px;
  outline: none;
  border: 0;
}

.boton_fotos{
    margin-top: 20px;
    background: #302C28;
  width: 370px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  color:white;
  font-size: 15px;
  outline: none;
  border: 0;
  margin-bottom: 50px;
}

.errors{
    padding-top: 5px;
  color: rgb(211, 31, 31);
}
</style>