<template>
  <div id="test">
    <div class="container" v-if="user_name == ''">
      <scaling-squares-spinner
        class="spinner"
        :animation-duration="1250"
        :size="65"
        color="#da8f0f"
      />
    </div>
    <div class="container" v-else-if="test_in_progress == false">
      <TestIni @iniciarTest="startTest" :user_name="user_name"></TestIni>
    </div>
    <div
      class="container2"
      v-else-if="(test_in_progress == true) & (test_finish == false)"
    >
      <TestP @testTerminado="terminarTest"></TestP>
    </div>
    <div
      class="container"
      v-else-if="(test_finish == true) & (test_in_progress == true)"
    >
      <TestR
        :puntaje_respuesta="puntaje_final"
        :respuestas="respuestas"
      ></TestR>
    </div>
  </div>
</template>

<script>
import { ScalingSquaresSpinner } from "epic-spinners";
//import {Global} from "../Global.js";
//import axios from "axios";
import "../axiosInterceptor.js";
import TestIni from "./aux_components/TestIni.vue";
import TestP from "./aux_components/TestP.vue";
import TestR from "./aux_components/TestR.vue";

export default {
  name: "App",
  components: {
    TestIni,
    TestP,
    TestR,
    ScalingSquaresSpinner,
  },

  data() {
    return {
      user_name: "",
      test_in_progress: false,
      test_finish: false,
      puntaje_final: 0,
      respuestas: [],
    };
  },
  methods: {
    startTest(value) {
      this.test_in_progress = value;
    },
    terminarTest(data) {
      this.test_finish = true;
      this.puntaje_final = data.score;
      this.respuestas = data.answers;
    },
    traerNombre() {
      this.user_name = localStorage.getItem("user_names");
    },
  },
  mounted() {
    this.traerNombre();
  },
};
</script>

<style lang="scss" scoped>
#test {
  background: url("../assets/wallpaper.svg");
  background-position: center;
  background-size: cover;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 810px;
  height: 600px;
  margin-top: -300px;
  margin-left: -405px;
}

.spinner {
  margin: 200px auto;
}

.container2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 700px;
  margin-top: -350px;
  margin-left: -300px;
}
</style>